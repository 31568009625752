import React, { Component } from "react";
import Auth from "../common/Auth";
import Config from "../Config";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment'

/**
 * 
 * Displays the previous lotto results
 * 
 */
export default class Results extends Component {
  constructor() {
    super();
    this.auth = new Auth();
    this.config = new Config();
    this.$http = this.config.DConnect();
    this.state = {
      drawnum: [], games: [], game: 0, content: [{title:'', content:''}]
    };
  }

  componentDidMount() {
    this.getContent();
    this.getWinNumbers(this.state.game)
  }

  getWinNumbers = (value) => {
    // this.$http.get('web/web/game')
    //   .then(res => {
    //     let resdata = this.auth.decryptData(res.data)

    //     let games = resdata.filter(rdata => rdata.gametype == 1);

    //     this.$http.get('web/web/drawnum/0').then(res => {
    //       let resdata = this.auth.decryptData(res.data);
    //       let drawnum = resdata.filter(itm => itm.gamecode == value);

    //       this.setState({ games, drawnum });
    //     });
    //   })
  }

  getContent = () => {
    this.$http.get('web/web/portal/2')
      .then(res => {
        let content = this.auth.decryptData(res.data);

        this.setState({ content });
      }).catch((error) => { alert(error) })
  }

  handleChange = (e) => {
    let game = this.state.game;
    game = e.target.value;
    
    this.setState({ games:[], drawnum:[] });
    this.setState({ game });
  }

  formatGame = (cell) => {
    let game = this.state.games.filter(gm => gm.id == cell)
    let gamename = (game[0] !== undefined) ? game[0].gamename : cell

    return gamename
  }
  gamedate = (cell, row) => {
    return (
      <span>{moment(cell).format('D MMM, YYYY')}</span>
    )
  }
  gamename = (cell, row) => {
    let gamename = this.formatGame(cell)
    return (
      <span class="active">{gamename} (Event: {row.drawno})</span>
    )
  }
  winning = (cell, row) => {
    let winum = row.drawNumbers.split('-')
    return (
      <ul class="number-list">{winum.map(wn => (<li class="active">{wn}</li>))}</ul>
    )
  }
  machine = (cell, row) => {
    let manum = row.machineNumbers.split('-')
    return (
      <ul class="number-list">{manum.map(mn => (<li>{mn}</li>))}</ul>
    )
  }

  render() {
    let {content} = this.state;
    
    return (
      <div>
        <section class="inner-page-banner has_bg_image" data-background="assets/images/inner-page-bg.jpg" style={{ backgroundImage: 'url(assets/images/inner-page-bg.jpg)' }}>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="inner-page-banner-area">
                  <h1 class="page-title">{(content.length>0) ? content[0].title:''}</h1>
                  <nav aria-label="breadcrumb" class="page-header-breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="">Home</a></li>
                      <li class="breadcrumb-item">result</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>



        <section class="lottery-result-section section-padding">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="section-header text-center">
                  <h2 class="section-title">{(content.length>0) ? content[0].title : ''}</h2>
                  <p>{(content.length>0) ? content[0].content: ''}</p>
                  <br></br>

                </div>


              </div>
             

              <span style={{ width: "100%" }}>
              <div class="row">
                <div class="col-sm-1">Lottery</div>
                <div class="col-sm-11 d-flex"> 
                <select className="pull-left form-control" onChange={this.handleChange} value={this.state.game} style={{ width: "20%" }}>
                    <option value={''}>-- Select Game --</option>
                  {this.state.games.map(bt => (
                    <option value={bt.id}>{bt.gamename}</option>
                  ))}
                </select>
                <button className="btn  btn-primary" onClick={this.getWinNumbers(this.state.game)}>Display</button>
                </div>
              </div>
               

              </span>
              <span> 	&nbsp;</span>
              <BootstrapTable data={this.state.drawnum} pagination condensed >
                <TableHeaderColumn dataField='gamecode' dataFormat={this.gamename} isKey width='200px' dataSort>Event #</TableHeaderColumn>
                <TableHeaderColumn dataField='drawDate' width='160px' dataAlign="left" dataFormat={this.gamedate} dataSort>Draw Date</TableHeaderColumn>
                <TableHeaderColumn dataField='drawNumbers' width='160px' dataAlign="left" dataFormat={this.winning} dataSort >Winning Numbers</TableHeaderColumn>
                <TableHeaderColumn dataField='machineNumbers' width='160px' dataAlign="left" dataSort dataFormat={this.machine}>Machine Numbers</TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </section>
      </div>
    )
  }
}