import React, { Component } from "react";
import Auth from "../common/Auth";
import Config from "../Config";
import Parser from 'html-react-parser';

/**
 * 
 * Displays general about us page
 * 
 */
export default class About extends Component {
  constructor() {
    super();
    this.auth = new Auth();
    this.config = new Config();
    this.$http = this.config.DConnect();
    this.state = {
      about: {title:'', content:''}
    };
  }

  componentDidMount = () => {
    this.getContent();
  }

  getContent = () => {
    this.$http.get('web/web/portal/4')
      .then(res => {
        let content = this.auth.decryptData(res.data);
        let about = content.filter(ct => ct.category==1);

        this.setState({ about: about[0] });
      }).catch((error) => { console.log(error.message) })
  }

  render() {
    let {about} = this.state;

    return (
      <div>
      <section class="inner-page-banner has_bg_image" data-background="assets/images/inner-page-bg.jpg" style={{backgroundImage:'url(assets/images/inner-page-bg.jpg)'}}>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="inner-page-banner-area">
                <h1 class="page-title">{about.title}</h1>
                <nav aria-label="breadcrumb" class="page-header-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="">Home</a></li>
                    <li class="breadcrumb-item active">About</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="about-section section-padding">
        <div class="container-fluid">
          <div class="row justify-content-between">
            <div className="col-xs-1">&nbsp;</div>
            <div class="col-xl-4 ">
              <div class="thumb">
                <img src="assets/images/about-image.jpg" alt="image" />
              </div>
            </div>
            <div class="col-xl-7">
              <div class="content">
                <h2 class="title">{about.title}</h2>
                {Parser(about.content)}
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}