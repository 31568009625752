import React, { Component } from "react"
import ReactDOM from 'react-dom'
import Auth from "../common/Auth"
import Config from "../Config"
import moment from 'moment'
import _ from 'lodash'
import axios from 'axios'
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

var amountformat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })

/**
 * 
 * Form agent use to sell unit
 * 
 */
export default class Sales extends Component{
    constructor(props) {
        super(props)
    
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            roleid: this.props.roleid,
            formItems: { username: this.auth.getUser(), startDate: moment(),endDate: moment()},
            startDate: moment(),
            endDate: moment(),
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            games: [], 
            salesTotal: 0.00, salesData: [], salesGroup: {}, agentSales: [],
            retailerList:[], retailers: [], retailer: '',
            isAgent: false
        }
    }

    componentDidMount(){
        this.getGamesData()
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            let roleid = nextProps.roleid

            this.setState({roleid})

            this.getGamesData()
        }
    }

    getGamesData = () => {
        this.$http.get('web/web/game')
        .then(res => {
            let games = this.auth.decryptData(res.data)

            this.$http.get('web/web/' + this.auth.getUser() + '/writers')
            .then(res => {
                let retailerList = this.auth.decryptData(res.data)


                let retailer = (retailerList[0] !== undefined) 
                ?retailerList[0].username : ''

                this.setState({ games, retailerList, retailer })

                this.getSalesData()
            })
        })
    }

    getSalesData = () => {
        let formItems = this.state.formItems

        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'web/web/sales'))
        .then((res) => {
            let resdata = this.auth.decryptData(res.data)

            let isAgent = this.state.isAgent
            let retailers = this.state.retailers
            let retailer = this.state.retailer
            let salesGroup = resdata
            //let salesData = resdata
            if(resdata[0] !== undefined && resdata[0].agent !== undefined){
                let retailerList = this.state.retailerList
                salesGroup = _.groupBy(resdata, 'retailer')

                retailers = Object.keys(salesGroup).map(key => {
                    let retail = retailerList.filter(ret => ret.username == key)
                    let retailname = (retail[0] !== undefined) 
                    ? retail[0].firstname + ' ' + retail[0].lastname : key
                    let vretailer = {}
                    return vretailer[key] = retailname
                })
                
                isAgent = true
            }
            
            this.setState({ salesGroup, isAgent, retailers })

            this.displayData()
        })
    }

    dateFormat = (cell, row) => moment(cell).format('DD MMM, YYYY <br /> hh:mm a')
    curFormat = (cell, row) => amountformat.format(cell)
    gameFormat = (cell, row) => {
        let game = this.state.games.filter(gm => gm.id == cell)
        let gamename = (game[0] !== undefined) ? game[0].gamename : cell

        return gamename + ' (#' + row.drawno + ')' 
    }

    displayData = () => {
        let retailer = this.state.retailer
        let salesGroup = this.state.salesGroup
        let salesData = (this.state.isAgent) ? salesGroup[retailer] : salesGroup
        if(salesData == undefined){
            salesData = []
        }

        let salesTotal = _.reduce(salesData, (sum, cur) => {return sum + parseFloat(cur.ticketTotal)},0)

        this.setState({ salesData, salesTotal})
    }

    handleChange = (e) => {
        let retailer = e.target.value

        this.setState({ retailer })
    }

    handleEvent = (event, picker) => {
        let formItems = this.state.formItems

        formItems['startDate'] = picker.startDate
        formItems['endDate'] = picker.endDate
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            formItems
        });

        this.getSalesData()
    }

    render() {
        let start = this.state.startDate.format('D MMM, YYYY')
        let end = this.state.endDate.format('D MMM, YYYY')
        let label = start + ' - ' + end
        if (start === end) {label = start}
    
        return (
            <div class="blog-details-wrapper">
                <h3 class="post-title">My Sales </h3>
                <hr />
                <div class="content">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="btn-group btn-group-sm pull-right">
                                <button className="btn btn-default">DateRange: </button>
                                <DatetimeRangePicker
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    ranges={this.state.ranges}
                                    onEvent={this.handleEvent}
                                    className="selected-date-range-btn btn btn-default">
                                        <div className="pull-left">
                                            <i className="fa fa-calendar" />&nbsp; &nbsp; 
                                            <span>{label}</span>
                                        </div>
                                        <div className="pull-right">
                                            &nbsp; &nbsp;<i className="fa fa-angle-down" />
                                        </div>
                                </DatetimeRangePicker>
                                {this.state.isAgent &&
                                <button className="btn btn-default">Retailer: </button>}
                                {this.state.isAgent &&
                                <select id="" name="operator" onChange={this.handleChange} value={this.state.formItems.retailer} className="btn btn-default" style={{ width: '200px', height: '30px' }}>
                                    {this.state.retailerList.map(retailer =>
                                        <option value={retailer.username}>{retailer.firstname} {retailer.lastname}</option>
                                    )}
                                </select>}
                                <button onClick={this.displayData} className="btn btn-info">Display</button>
                            </div>
                            <div className="clearfix"></div>
                            <hr />
                        </div>
                        <div className="col-lg-12">
                            <div className="pull-left" style={{lineHeight:'38px'}}>Total Sales: GH&cent;<b>{amountformat.format(this.state.salesTotal)}</b></div>
                            <BootstrapTable data={this.state.salesData} striped hover condensed search pagination>
                                <TableHeaderColumn dataField='saleDate' isKey dataSort dataFormat={this.dateFormat} width='120px'>Date</TableHeaderColumn>
                                <TableHeaderColumn dataField='gamecode' dataSort dataFormat={this.gameFormat} width='150px'>Game/Event</TableHeaderColumn>
                                <TableHeaderColumn dataField='ticketSerial' dataSort width='180px'>Ticket #</TableHeaderColumn>
                                <TableHeaderColumn dataField='ticketTotal' dataSort dataAlign="right" dataFormat={this.curFormat} width='120px'>Ticket Total</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}