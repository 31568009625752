import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config';
import moment from 'moment'

/**
 * 
 * Previews Items in a cart
 * 
 */
export default class Preview extends Component{
    constructor(){
        super()

        this.auth = new Auth();
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            cart:[],companySetup:[],imgReceipt:''
        }
    }

    componentDidMount(){
        this.getCart()
        this.getReportData()
    }

    getReportData = () =>{
      this.$http.get('admin/web/setup')
      .then(res => {
          let companySetup = this.auth.decryptData(res.data)
          this.setState({ companySetup })
      })
  }

    getCart = () => {
      
        this.$http.get('web/web/carts/' + this.auth.getUser())
          .then(res => {
            let cart = this.auth.decryptData(res.data)
            let data=(cart !='')?cart[0]:''
            this.getTicket(data.game,data.drawno)
            this.setState({ cart })
          })
      }

      getTicket=(game,drawno)=>{
        this.$http.get('web/web/tickets/'+this.auth.getUser()+'/'+game+'/'+drawno)
        .then(res => {
          let tickets = this.auth.decryptData(res.data)
          alert(JSON.stringify(tickets))
          this.setState({ tickets })
        })
      }
      getImageTicket = (ticketSerial) => {
        if(ticketSerial !== ''){
            let url = 'admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/ticketimage/' + ticketSerial
        
            this.$http.get(url, {responseType: 'arraybuffer'})
            .then((res) => {
                let imgReceipt = new Buffer(res.data, 'binary').toString('base64')
                
                this.setState({ imgReceipt })
            }).catch((error)=>{alert("Image: " + error)})
        }
    }
    handleChange = (e) => {
        let frmLogin = this.state.frmLogin
        frmLogin[e.target.name] = e.target.value
        this.setState({ frmLogin })
    }

    doLogin = (e) => {
        e.preventDefault()
        
        this.auth.login(this.state.frmLogin)
    }

    doLoginMobile = (memail,mpwd) => {
      let frmLogin={email: memail, pwd:mpwd, role: 0}
      this.auth.login(frmLogin)
  }
    render(){
     
        return(
            <div id="myModal" class="modal fade" role="dialog">
            <div class="modal-dialog">
          
            <div className="modal-dialog" style={{width:'350px'}}>  
                        <div className="modal-content"> 
                            <div className="modal-header">
                                <h5 className="modal-title"><b>Ticket Details</b></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            </div>
                            <div className="modal-body" >
                                <img id="receiptImageCon" src={`data:image/jpeg;base64,${this.state.imgReceipt}`} style={{width:'300px'}} />
                            </div>
                        </div>
                    </div>
          
            </div>
          </div>
        )
    }

}