import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config';
import moment from 'moment'
import axios from 'axios'
import $ from 'jquery'

/**
 * 
 * Allows an agent to assign POS to a writer
 * 
 */
export default class AssignPOS extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth();
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems:{
                id:0, serial:'', brand:'', model:'', imei:'', username:'', 
                createdby:this.auth.getUser(), status:'0', agent:this.auth.getUser(),
                createdon:new Date(), modifiedby:null, modifiedon:null,
                curuser:this.auth.getUser(), token:this.auth.getToken()
            },
            retailers:this.props.writers,
            rowData: this.props.rowData,
            terminals: this.props.terminals
        }
    }

    componentDidMount = () => {
        this.processFormItems(this.props.terminals, this.props.rowData)
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            let retailers = nextProps.writers
            let rowData = nextProps.rowData
            let terminals = nextProps.terminals

            this.setState({ retailers, rowData, terminals })

            this.processFormItems(terminals, rowData)
        }

    }

    processFormItems = (terminals, item) => {
        let fitem = terminals.filter(term => term.id == item)
        let formItems = {id:0, serial:'', brand:'', model:'', imei:'', username:'', 
            createdby:this.auth.getUser(), status:'0', agent:this.auth.getUser(),
            createdon:new Date(), modifiedby:null, modifiedon:this.auth.getToken()}

        if(fitem[0] !== undefined){
            formItems = fitem[0]
            formItems['username'] = this.state.retailers[0].username
            formItems['modifiedby'] = this.auth.getUser()
        }

        this.setState({ formItems })
    }

    handleChange = (e) =>{
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value
        
        this.setState({ formItems })
    }

    formSubmit = (e) => {
        e.preventDefault()

        let formItems = this.state.formItems

        if(formItems.username.trim() == ''){
            alert("The POS is not assigned to a writer....")
            return
        }

        formItems['modifiedon'] = new Date()
        formItems['status'] = 0
        
        ///alert(JSON.stringify(formItems))
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'web/web/assignterminal'))
        .then((res) => {
            alert(this.auth.decryptText(res.data))
            $('#btnposcancel').click()
            this.props.getAssignedTerminals()
            
        }).catch((error)=>{alert(error)})
    }

    render(){
        return(
            <div className="modal fade login-modal" id="assignPOSModal" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header"><h3>Assign POS</h3></div>
                        <div className="modal-body">
                            <form id="frmAssignPOS" className="registration-form cmn-frm" onSubmit={this.formSubmit}>
                                <div className="row">
                                    <div className="col-lg-10">
                                        <div className="">IMEI: <b>{this.state.formItems.imei}</b></div>
                                        <div className="">Serial No: <b>{this.state.formItems.serial}</b></div>
                                        <hr />
                                        <div className="frm-group">
                                            <label>Writer: </label>
                                            <select type="text" name="username"  
                                            value={this.state.formItems.username} onChange={this.handleChange}>
                                                {this.state.retailers.map(retail => {
                                                    return(<option value={retail.username}>{retail.firstname} {retail.lastname}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-group btn-group-sm pull-right clearfix">
                                <button id="btnposcancel" className="btn btn-warning" data-dismiss="modal">
                                    <i className="fa fa-lg fa-backward"></i> Cancel
                                </button>
                                <button type="submit" className="btn btn-success" form="frmAssignPOS">
                                    <i className="glyphicon glyphicon-save"></i> Assign
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}