import React, { Component } from "react";
import Auth from "../common/Auth";
import Config from "../Config";
import moment from 'moment';
import Parser from 'html-react-parser';

/**
 * 
 * Displays NEWS page
 * 
 */
export default class News extends Component {
  constructor() {
    super();
    this.auth = new Auth();
    this.config = new Config();
    this.$http = this.config.DConnect();

    this.state = {
      news: {title:'', content:''},
      newsItem: [{title:'',content:''}]
    };
  }

  componentDidMount = () => {
    this.getContent();
  }

  getContent = () => {
    this.$http.get('web/web/portal/4')
      .then(res => {
        let content = this.auth.decryptData(res.data);
        
        let news = content.filter(ct => ct.category===2 && ct.categorySub===0);
        let newsItem = content.filter(ct => ct.category==2 && ct.categorySub==1);

        //alert(JSON.stringify(news))
        this.setState({ news: news[0], newsItem });
      }).catch((error) => { console.log(error.message) })
  }

  render() {
    let {news, newsItem} = this.state;

    return (
      <div>
        <section class="inner-page-banner has_bg_image" data-background="assets/images/inner-page-bg.jpg" style={{backgroundImage:'url(assets/images/inner-page-bg.jpg)'}}>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="inner-page-banner-area">
                  <h1 class="page-title">{news.title}</h1>
                  <nav aria-label="breadcrumb" class="page-header-breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="home-one.html">Home</a></li>
                      <li class="breadcrumb-item active">News</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="blog-section section-padding">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-12">
                <div class="row m-bottom-not-30">

                {newsItem.map((nitm, i) => (
                  <div class="col-lg-4 col-md-6">
                    <div class="post-item m-bottom-30">
                      <div class="thumb">
                        <img src={`assets/images/blog/m${i+1}.jpg`} alt="image" />
                      </div>
                      <div class="content">
                        <h3 class="post-title"><a href="#0">{nitm.title}</a></h3>
                        <ul class="post-meta">
                          <li><a href="#0"><span>BY</span>{nitm.location}</a></li>
                          <li><a href="#0"><i class="fa fa-calendar"></i>{moment(nitm.createdon).format('DD MMM YYYY')}</a></li>
                        </ul>
                        <p>{Parser(nitm.content.substring(0,100))}...</p>
                        <a href="#0" class="blog-btn">read more</a>
                      </div>
                    </div>
                  </div>
                ))}
                </div>
                <div class="row">
                  <div class="col-12">
                    <nav class="d-pagination" aria-label="Page navigation example">
                      <ul class="pagination justify-content-start">
                        <li class="page-item previous"><a href="#"><i class="fa fa-chevron-left"></i>prev</a></li>
                        <li class="page-item active"><a href="#">1</a></li>
                        <li class="page-item"><a href="#">2</a></li>
                        <li class="page-item"><a href="#">3</a></li>
                        <li class="page-item next"><a href="#">next<i class="fa fa-chevron-right"></i></a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}