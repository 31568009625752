import React, { Component } from "react";
import Auth from "../common/Auth";
import Config from "../Config";
import Parser from 'html-react-parser';

/**
 * 
 * Displays Privacy Policy page
 * 
 */
export default class Disclaimer extends Component {
  constructor() {
    super();
    this.auth = new Auth();
    this.config = new Config();
    this.$http = this.config.DConnect();
    this.state = {
      privacy: {title:'', content:''}
    };
  }

  componentDidMount = () => {
    this.getContent();
  }

  getContent = () => {
    this.$http.get('web/web/portal/4')
      .then(res => {
        let content = this.auth.decryptData(res.data);
        
        let privacy = content.filter(ct => ct.category===6 && ct.categorySub===0);

        //alert(JSON.stringify(news))
        this.setState({ privacy: privacy[0] });
      }).catch((error) => { console.log(error.message) })
  }

  render() {
    let {privacy} = this.state;

    return (
      <div>
        <section class="inner-page-banner has_bg_image" data-background="assets/images/inner-page-bg.jpg" style={{backgroundImage:'url(assets/images/inner-page-bg.jpg)'}}>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="inner-page-banner-area">
                  <h1 class="page-title">{privacy.title}</h1>
                  <nav aria-label="breadcrumb" class="page-header-breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="">Home</a></li>
                      <li class="breadcrumb-item">{privacy.title}</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="lottery-result-section section-padding">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="section-header text-center">
                  <h2 class="section-title">{privacy.title}</h2>
                  {Parser(privacy.content)}
                  </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}