import React, { Component } from "react";
import Auth from "../common/Auth";
import Config from "../Config";

/**
 * 
 * Displays contact us page
 * 
 */
export default class Contact extends Component {
  constructor() {
    super()

    this.auth = new Auth()
    this.config = new Config()
    this.$http = this.config.DConnect()

    this.state = {
      formItems:[]
    }
  }

  componentDidMount(){
this.getReportData()
  }


  getReportData = () =>{
    this.$http.get('admin/web/setup')
    .then(res => {
        let formItems = this.auth.decryptData(res.data)
        formItems['curuser'] = this.auth.getUser()
        formItems['token'] = this.auth.getToken()
        this.setState({ formItems })
    })
}
submit=(e)=>{
e.preventDefault()
alert("Thanks for contacting us,Kindly call our phone if request is taking long...")
window.location.reload(true)
}
  render() {
    let {formItems} = this.state;
    return (
      <div>
        <section class="inner-page-banner has_bg_image" data-background="assets/images/inner-page-bg.jpg" style={{backgroundImage:'url(assets/images/inner-page-bg.jpg)'}}>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="inner-page-banner-area">
                  <h1 class="page-title">contact us</h1>
                  <nav aria-label="breadcrumb" class="page-header-breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="">Home</a></li>
                      <li class="breadcrumb-item active">contact us</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section class="contact-section">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="contact-wrapper">
                  <div class="contact-area d-flex">
                    <div class="contact-form-area">
                      <h3 class="title">Get In Touch</h3>
                      <p>If you have any questions or queries our helpful support team will be more than happy to assist.</p>
                      <form class="contact-form">
                        <div class="form-grp">
                          <input type="text" name="contact_name" id="contact_name" placeholder="Full Name" />
                        </div>
                        <div class="form-grp">
                          <input type="email" name="contact_email" id="contact_email" placeholder="Email Address" />
                        </div>
                        <div class="form-grp">
                          <input type="tel" name="contact_phone" id="contact_phone" placeholder="Phone No" />
                        </div>
                        <div class="form-grp">
                          <textarea name="contact_message" id="contact_message" placeholder="Message"></textarea>
                        </div>
                        <div class="form-grp">
                          <input class="submit-btn" type="submit" value="sent message"  onClick={this.submit}/>
                        </div>
                      </form>
                    </div>
                    
                    <div class="address-area has_bg_image" data-background="assets/images/contact-mg.jpg">
                      <div class="address-area-header">
                        <h3 class="title">We Are Available</h3>
                        <span>{formItems.address}</span>
                        <br />
                        <span>C175/5 Torgbor Link, Kanda, Accra </span>
                        <br />
                        <span>(Behind the Czech Republic Embassy, Kanda)</span>
                      </div>
                      <div class="contact-address">
                        <h3 class="title">Contact Us</h3>
                        <ul>
                          <li>
                            <div class="icon"><i class="fa fa-phone"></i></div>
                            <div class="content">
                              <p>{formItems.phone}</p>
                            </div>
                          </li>
                          <li>
                            <div class="icon"><i class="fa fa-envelope-o"></i></div>
                            <div class="content">
                              <p>{formItems.email}</p>
                              <p>{formItems.website}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="contact-wrapper-footer">
                    <span>Please see our Frequent Asked Questions (FAQ) page to read more information:</span>
                    <a href="#" class="cmn-btn">check FAQs</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <div class="map-section"><div id="map"></div></div>
      </div>
    
   )
  }
}