import React, { Component } from "react"
import { BrowserRouter, Link } from 'react-router-dom'
import ReactDOM from 'react-dom'
import Auth from "../common/Auth"
import Config from "../Config"
import _ from 'lodash'
import Dashboard from "./Dashboard"
import ProfileDetails from "./ProfileDetails"
import Games from "./Games"
import Credits from "./Credits"
import Sales from "./Sales"
import Wallet from "./Wallet"
import Wins from "./Wins"
import Purchases from "./Purchases"
import Writers from "./Writers"
import AgentPayout from "./AgentPayout"

let profilemenu = {
    1:['My Dashboard', 'dashboard'], 2:['My Details','user'], 3:['My Units','credit-card'], 4:['My Wallet','money'],
    5:['My Sales','calculator'], 6:['My Wins','gift'], 7:['My Tickets','gamepad'], 8:['My Transactions','cart-plus'], 
    9:['Writers','user-plus'], 10:['Agent Payout', 'user-tag']
}
let rolemenu = {1:[1, 2], 4:[1, 2, 3, 4, 5, 6, 9, 10], 5:[1, 2, 3, 4, 5, 6], 6:[1, 2, 6, 7, 8]} //6:[1, 2, 4, 6, 7, 8]

/**
 * 
 * Main page for agents and writers profile
 * 
 */
export default class Profile extends Component{
    constructor(props) {
        super(props)
    
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            user: {}
        }
    }

    componentDidMount(){
        this.getUser(1)
    }

    getUser = (area) => {
        this.$http.get('admin/web/user/' + this.auth.getUser())
        .then(res => {
            let user = this.auth.decryptData(res.data)

            this.setState({user})
            this.showMenu(user.auth)
            this.handleClick(area);
        })
    }

    showMenu = (roleid) => {
        let menu = <BrowserRouter>{Object.keys(profilemenu).map((k, i) => {
            if(rolemenu[roleid]!==undefined && rolemenu[roleid].includes(parseInt(k))){
                return(
                    <li><Link to="#" id={'promenu_' + parseInt(k)} onClick={() => this.handleClick(parseInt(k))}>
                        <i className={'fa fa-' + profilemenu[k][1]}></i> &nbsp; {profilemenu[k][0]}
                    </Link> </li>
                )
            }
        })}</BrowserRouter>

        ReactDOM.render(menu, document.getElementById('promenuCon'))
    }

    handleClick = (area) => {
        //alert(area)
        let itm = <Dashboard roleid={parseInt(this.state.user.auth)} />

        if(area == 2){itm = <ProfileDetails user={this.state.user} getUser={this.getUser} />}
        else if(area == 3){itm = <Credits roleid={parseInt(this.state.user.auth)} />}
        else if(area == 4){itm = <Wallet roleid={parseInt(this.state.user.auth)} />}
        else if(area == 5){itm = <Sales roleid={parseInt(this.state.user.auth)} />}
        else if(area == 6){itm = <Wins roleid={parseInt(this.state.user.auth)} />}
        else if(area == 7){itm = <Games roleid={parseInt(this.state.user.auth)} />}
        else if(area == 8){itm = <Purchases roleid={parseInt(this.state.user.auth)} />}
        else if(area == 9){itm = <Writers roleid={parseInt(this.state.user.auth)} />}
        else if(area == 10){itm = <AgentPayout roleid={parseInt(this.state.user.auth)} />}
        
        ReactDOM.render(itm, document.getElementById('proContentCon'))
    }

    render() {
    
        return (
          <div>
              <section class="inner-page-banner has_bg_image" data-background="assets/images/inner-page-bg.jpg" style={{backgroundImage:'url(assets/images/inner-page-bg.jpg)'}}>
                <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                    <div class="inner-page-banner-area">
                        <h1 class="page-title">{this.auth.getRole()} Profile</h1>
                        <nav aria-label="breadcrumb" class="page-header-breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="home-one.html">Home</a></li>
                            <li class="breadcrumb-item active">Profile</li>
                        </ol>
                        </nav>
                    </div>
                    </div>
                </div>
                </div>
            </section>

            <section class="blog-details-section section-padding">
                <div class="container">
                    <div class="row justify-content-between">
                        <div id="proContentCon" class="col-lg-8"></div>
                        <div class="col-lg-3">
                            <div className="sidebar">
                            <div class="widget widget_categories">
                            <h3 class="widget-title">{this.state.user.firstname} {this.state.user.lastname}</h3>
                            <ul id="promenuCon"></ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          </div>
        )
    }
}