import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config';
import moment from 'moment'
import axios from 'axios'
import $ from 'jquery'
import ReactDOM from 'react-dom'

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

/**
 * 
 * Form to transfer unit
 * 
 */
export default class TransferUnitForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth();
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            commision: 0.00,
            purchase:0.00,
            loader: false, disable: false,
            writers: this.props.writers,
            formItems: {
                usertype:3, totalUnit:0, commission:0, unitfrom:this.auth.getUser(), unito: '', transtype:1, 
                curuser: this.auth.getUser(), token: this.auth.getToken()
            }
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.writers !== this.props.writers){
            let writers = nextProps.writers
            let formItems= {
                usertype:3, totalUnit:0, commission:0, unitfrom:this.auth.getUser(), unito: '', transtype:1, 
                curuser: this.auth.getUser(), token: this.auth.getToken()
            }

            this.setState({ writers, formItems })
        }
    }

    handleChange = (e) => {
        let unit = e.target.value
        let commision = ((100/80) * unit) - unit
        let purchase = unit

        this.setState({ commision, purchase })
    }

    handleType = (e) => {
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value

        this.setState({ formItems })
    }

    formSubmit = (e) => {
        if(this.state.disable){return;}
        this.setState({ loader:true, disable:true })
        e.preventDefault()

        let formItems = this.state.formItems
        if(formItems.unito == ''){
            formItems['unito'] = this.state.writers[0].username
        }
        
        formItems['totalUnit'] = parseFloat(this.state.purchase) + parseFloat(this.state.commision)
        formItems['commission'] = this.state.commision

        if(parseFloat(formItems.totalUnit) > 0){
            this.setState({ loader:false, disable:false })

            let formData = this.auth.encryptData(JSON.stringify(formItems))
            axios(this.config.PostOptions(formData, 'web/web/unitransfer'))
            .then((res) => {
                $('#btnunitcancel').click()
                alert(this.auth.decryptText(res.data))
                this.props.getTransfers('out');
            }).catch((error)=>{alert(error); this.setState({ loader:false, disable:false })})
        }else{
            this.setState({ loader:false, disable:false })

            alert('Unit amount cannot be zero (0), please check and try again...')
        }
    }

    loading = () => <div id="loader"></div>

    render(){
        return(
            <div className="modal fade login-modal" id="transferUnitModal" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header"><h3>Transfer Unit</h3></div>
                        <div className="modal-body">
                            <form id="frmUnitTransfer" className="smart-form" onSubmit={this.formSubmit}>
                                    <div className="row">
                                        <div className="col-xs-12 col-md-8">
                                            <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Writer: </label>
                                            <select name="unito" value={this.state.formItems.unito} onChange={this.handleType} style={{width:'150px'}}>
                                                {this.state.writers.map(writ => (
                                                    <option value={writ.username}>{writ.firstname} {writ.lastname}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-xs-12 col-md-8">
                                            <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Type: </label>
                                            <select name="transtype" value={this.state.formItems.transtype} onChange={this.handleType} style={{width:'150px'}}>
                                                <option value='1'>Increase</option>
                                                <option value='2'>Decrease</option>
                                            </select>
                                        </div>
                                        <div className="col-xs-12 col-md-8">
                                            <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Unit Amount:</label>
                                            <input type="number" min="0" name="purchase" placeholder="Unit Transfer" style={{width:'150px'}} 
                                                value={this.state.purchase} onChange={this.handleChange}/>
                                                <hr />
                                        </div>
                                        <div className="col-xs-12 col-md-8">
                                            <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Commission: </label><b>{numformat.format(this.state.commision)}</b>
                                        </div>
                                        <div className="col-xs-12 col-md-8">
                                            <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Total Unit: </label><b>
                                                {numformat.format(parseFloat(this.state.commision) + parseFloat(this.state.purchase))}
                                            </b>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button id="btnunitcancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                                <button type="submit" form="frmUnitTransfer" className="btn btn-primary" disabled={this.state.disable}>Send Unit</button>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}