import React, { Component } from "react"
import axios from 'axios'
import ReactDOM from 'react-dom'
import Auth from "../common/Auth"
import Config from "../Config"
import _ from 'lodash'
import $ from 'jquery'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import WriterForm from './WriterForm'
import AssignPOS from "./AssignPOS"

var curFormat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })

/**
 * 
 * List of Agents writers
 * 
 */
export default class Writers extends Component{
    constructor(props) {
        super(props)
    
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            roleid: this.props.roleid,
            totalWriters: 0,
            retailers: [],
            noposretailers: [],
            agentills: [],
            contacts: [],
            agenterminal: [],
            assignedterminals: [],
            selectedTerminal: 0,
            curwriter:{}, curcontact:{}
        }
    }

    componentDidMount(){
        this.getWriters()

        this.getAssignedTerminals()
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.roleid !== this.props.roleid){
            let roleid = nextProps.roleid

            this.setState({roleid})

            this.getWriters()

            this.getAssignedTerminals()
        }
    }

    getWriters = () => {
        this.$http.get('web/web/' + this.auth.getUser() + '/writers')
        .then(res => {
            let retailers = this.auth.decryptData(res.data)
            let totalWriters = retailers.length

            this.getWritersContacts()

            this.$http.get('web/web/' + this.auth.getUser() + '/tillagent')
            .then(res => {
                let agentills = this.auth.decryptData(res.data)

                this.$http.get('web/web/' + this.auth.getUser() + '/terminalagent')
                .then(res => {
                    let agenterminal = this.auth.decryptData(res.data)

                    let userassigned = agenterminal.map(usass => usass.username)
                    let noposretailers = retailers.filter(ret => !userassigned.includes(ret.username))

                    this.setState({ retailers, agentills, agenterminal, totalWriters, noposretailers })
                })
            })
        })
    }

    getWritersContacts = () => {
        this.$http.get('web/web/' + this.auth.getUser() + '/writerscontacts')
        .then(res => {
            let contacts = this.auth.decryptData(res.data)

            this.setState({ contacts })
        })
    }

    getAssignedTerminals = () => {
        this.$http.get('web/web/' + this.auth.getUser() + '/assignedterminal')
        .then(res => {
            let assignedterminals = this.auth.decryptData(res.data)

            this.setState({ assignedterminals })
        })
    }

    handleClick = (area, value) => {
        if(area == 'unlink'){
            alert('Unassigned')
        }else if(area == 'pos'){
            let selectedTerminal = value
            this.setState({ selectedTerminal })
        }
    }

    actionFormat = (cell, row) => {
        return(
            <div className="btn-group btn-group-sm">
                {row.username == '' ? 
                <button className="btn btn-primary" title="Assign" onClick={() => this.handleClick('pos', cell)}  data-toggle="modal" data-target="#assignPOSModal"><i className="fa fa-unlink fa-lg"></i></button> :
                <button className="btn btn-danger" title="Unassign" onClick={() => this.unassignTerminal(row, '4')}><i className="fa fa-link fa-lg"></i></button>}
            </div>
        )
    }

    unassignTerminal = (rowData, status) => {
        if(parseInt(status) == 4){
            rowData['username'] = ''
            rowData['modifiedby'] = ''
            rowData['modifiedon'] = null
        }
        
        rowData['status'] = status

        let formData = this.auth.encryptData(JSON.stringify(rowData))
        axios(this.config.PostOptions(formData, 'web/web/assignterminal'))
        .then((res) => {
            alert(this.auth.decryptText(res.data))
            this.getAssignedTerminals()
            
        }).catch((error)=>{alert(error)})
    }

    assignCurWriter = (curwriter) => {
        let curcontact = {}

        if(curwriter !== undefined){
            let contact = this.state.contacts.filter(cont => cont.ownerId == curwriter.username)
            curcontact = (contact[0] !== undefined) ? contact[0] : {}
        }
        

        this.setState({ curwriter, curcontact })
    }

    render() {
    
        return (
            <div class="blog-details-wrapper">
                <h3 class="post-title">My Writers </h3>
                <hr />
                <div class="content">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="btn-group btn-group-sm pull-right">
                                <button className="btn btn-primary" onClick={() => this.assignCurWriter({})} data-toggle="modal" data-target="#addwriterModal"><i className="fa fa-plus fa-lg"></i> Add Writer</button>
                            </div>
                            <div className="btn-group btn-group-sm pull-left">
                                <span className="btn btn-default" >Total Writers: <b>{this.state.totalWriters}</b></span>
                            </div>
                            <div className="clearfix" />
                            <hr />
                        </div>
                        <div className="col-lg-12">
                            <div className="faq-wrapper">
                                <ul className="nav nav-tabs" id="writerTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="writers-tab" data-toggle="tab" href="#writers" role="tab" aria-controls="writers" aria-selected="false">Writers</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pos-tab" data-toggle="tab" href="#pos" role="tab" aria-controls="pos" aria-selected="false">POS</a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="writerTabContent" style={{padding:'0px'}}>
                                    <div className="tab-pane fade show active" id="writers" role="tabpanel" aria-labelledby="writers-tab"> 
                                        <div className="row mt-mb-15">
                                            {this.state.retailers.map(retailer => {
                                                let unitill = this.state.agentills.filter(atill => atill.username == retailer.username && atill.tillType == 1)
                                                let walletill = this.state.agentills.filter(atill => atill.username == retailer.username && atill.tillType == 2)
                                                let terminal = this.state.agenterminal.filter(aterm => aterm.username == retailer.username)
                                                let contact = this.state.contacts.filter(cont => cont.ownerId == retailer.username)

                                                let unitBal = (unitill[0]!==undefined) ? unitill[0].closeBal : 0
                                                let walletBal = (walletill[0]!==undefined) ? walletill[0].closeBal : 0
                                                let imei = (terminal[0] !== undefined) ? terminal[0].imei : ''
                                                let writerContact = (contact[0] !== undefined) ? contact[0].location : ''
                                                
                                                return(<div className="col-lg-4 col-sm-6">
                                                    <div className="choose-item text-center choose-item--gray-border">
                                                        <div className="front">
                                                            <div className="icon" style={{overflow:'hidden'}}>
                                                                <img src={this.config.ImagePath('custfiles') + '/' + retailer.username + '.' + retailer.filex} alt="IMG" />
                                                            </div>
                                                            <div className="title">
                                                                {retailer.firstname} {retailer.lastname}
                                                                <br />({retailer.username})
                                                            </div>
                                                        </div>
                                                        <div className="back" style={{padding:'10px', textAlign:'left'}}>
                                                            <p>
                                                                IMEI: <b>{imei}</b>
                                                                <br />Phone: <b>{retailer.mobile}</b>
                                                                <br />Location: <b>{writerContact}</b>
                                                                <br />Wallet: <b>GH&cent;{curFormat.format(walletBal)}</b>
                                                                <br />Unit: <b>{curFormat.format(unitBal)}</b>
                                                            </p>
                                                            <p>
                                                                <button className="btn btn-info btn-sm" onClick={() => this.assignCurWriter(retailer)} data-toggle="modal" data-target="#addwriterModal">
                                                                    <i className="fa fa-edit fa-lg"></i> Edit
                                                                </button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pos" role="tabpanel" aria-labelledby="pos-tab"> 
                                        <BootstrapTable data={this.state.assignedterminals} striped hover condensed search pagination>
                                            <TableHeaderColumn dataField='imei' isKey dataSort width="180px">IMEI</TableHeaderColumn>
                                            <TableHeaderColumn dataField='serial' dataSort width="250px">Serial No.</TableHeaderColumn>
                                            <TableHeaderColumn dataField='username' dataSort width="160px">Writer</TableHeaderColumn>
                                            <TableHeaderColumn dataField='id' dataFormat={this.actionFormat}></TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WriterForm getWriters={this.getWriters} curwriter={this.state.curwriter} curcontact={this.state.curcontact}  />
                <AssignPOS writers={this.state.noposretailers} terminals={this.state.assignedterminals} rowData={this.state.selectedTerminal} getAssignedTerminals={this.getAssignedTerminals} />
            </div>
        )
    }
}