import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config';
import moment from 'moment'
import axios from 'axios'
import $ from 'jquery'
import NumberFormat from "react-number-format"

const ISDN = [{'id':'MTN', 'name':'MTN'}, {'id':'VDF', 'name':'Vodafone'}, 
{'id':'ATL', 'name':'Airtel'}, {'id':'TIGO', 'name':'Tigo'}]

/**
 * 
 * Loads money from mobile money to wallet
 * 
 */
export default class LoadFundsForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth();
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems: {
                username : this.auth.getUser(),
                isdn : 'MTN',
                mobile : this.props.user.mobile,
                voucher : '',
                amount : 0 },
            user:this.props.user
        }
    }

    componentDidMount(){
        //alert(this.auth.getUser())
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.user !== this.state.user){
            let user = nextProps.user
            let formItems = this.state.formItems
            formItems['mobile'] = user.mobile

            this.setState({ formItems, user })
        }
    }

    handleChange = (e) => {
        let formItems = this.state.formItems
        let val = e.target.value

        if(e.target.name == 'amount'){
            val = parseFloat(val.replace(/\.|,/gi, ''))
        }
        formItems[e.target.name] = val

        this.setState({ formItems })
    }

    formSubmit = (e) => {
        e.preventDefault()

        $('#btnfundsload').hide();
        let formData = this.auth.encryptData(JSON.stringify(this.state.formItems))
        axios(this.config.PostOptions(formData, 'web/web/loadcash'))
        .then(res => {
            alert(this.auth.decryptText(res.data))

            $('#btnfundscancel').click();
            this.props.getUser();
        }).catch((error)=>{alert(error );$('#btnfundsload').show()})
    }

    render(){
        return(
            <div className="modal fade login-modal" id="loadFundsModal" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header"><h3>Load Funds</h3></div>
                        <div className="modal-body">
                            <form id="frmLoadFunds" className="smart-form" onSubmit={this.formSubmit}>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <label style={{width:'120px', marginRight:'10px'}} className='text-right'>ISDN: </label>
                                        <select name="isdn" value={this.state.formItems.isdn} onChange={this.handleChange} style={{width:'200px'}}>
                                            {ISDN.map(idn => (
                                                <option value={idn.id}>{idn.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-xs-12">
                                        <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Mobile No.: </label>
                                        <input type="text" name="mobile" value={this.state.formItems.mobile} onChange={this.handleChange} style={{width:'200px'}} />
                                    </div>
                                    {this.state.formItems.isdn == 'VDF' &&
                                    <div className="col-xs-12">
                                        <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Voucher.: </label>
                                        <input type="text" name="voucher" value={this.state.formItems.voucher} onChange={this.handleChange} style={{width:'200px'}} />
                                    </div>}
                                    <div className="col-xs-12">
                                        <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Amount: </label>
                                        <NumberFormat displayTtype="text" min="0"  id="amount" name="amount" style={{width:'200px'}}
                                            required thousandSeparator={true} isNumericString={true} allowNegative={false} allowEmptyFormatting={true}
                                            value={this.state.formItems.amount} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button id="btnfundscancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button id="btnfundsload" type="submit" form="frmLoadFunds" className="btn btn-primary">Load Funds</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}