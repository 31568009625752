import React, { Component } from "react"
import ReactDOM from 'react-dom'
import Auth from "../common/Auth"
import Config from "../Config"
import _ from 'lodash'
import ProfileForm from "./ProfileForm";
import PasswordForm from "./PasswordForm";

/**
 * 
 * Agent or writer profile details
 * 
 */
export default class ProfileDetails extends Component{
    constructor(props) {
        super(props)
    
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            user: this.props.user
        }
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            let user = nextProps.user

            this.setState({ user })
        }
    }

    render() {
        let { user } = this.state;

        return (
            <div class="blog-details-wrapper">
                <h3 class="post-title">My Details </h3>
                <hr />
                <div class="content">
                    <div className="row">
                        <div className="col-xs-12 col-sm-9">
                            <table className="table table-striped table-condensed">
                                <tbody>
                                    <tr><td>Username</td><td><b>{user.username}</b></td></tr>
                                    <tr><td>Firstname</td><td><b>{user.firstname}</b></td></tr>
                                    <tr><td>Lastname</td><td><b>{user.lastname}</b></td></tr>
                                    <tr><td>Middlename</td><td><b>{user.middlename}</b></td></tr>
                                    <tr><td>Email</td><td><b>{user.email}</b></td></tr>
                                    <tr><td>Mobile</td><td><b>{user.mobile}</b></td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-xs-12 col-sm-3">
                            <table className="table table-striped table-condensed">
                                <tbody>
                                    <tr><td><div className="table-bordered" style={{width:'100px', height:'120px'}}></div></td></tr>
                                    <tr><td><button className="btn btn-primary btn-sm btn-block" data-toggle="modal" data-target="#profileModal"><i classname="fa fa-edit"></i> Edit Details</button></td></tr>
                                    <tr><td><button className="btn btn-danger btn-sm btn-block" data-toggle="modal" data-target="#passwordModal"><i classname="fa fa-lock"></i> Change Password</button></td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <ProfileForm user={user} getUser={this.props.getUser} />
                <PasswordForm user={user} />
            </div>
        )
    }
}