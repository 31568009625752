import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config';
import moment from 'moment'

/**
 * 
 * Display form to enable an agent or writer to cashout wallet
 * 
 */
export default class CashoutForm extends Component{
    constructor(){
        super()

        this.auth = new Auth();
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {

        }
    }

    render(){
        return(
            <div className="modal fade login-modal" id="cashoutModal" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header"><h3>Cashout</h3></div>
                        <div className="modal-body">
                            Evolving
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}