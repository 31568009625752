import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config';
import moment from 'moment'
import axios from 'axios'
import $ from 'jquery'

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

/**
 * 
 * Allows agent to move unit from one writer to the other
 * 
 */
export default class ChangeUnitForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth();
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            commision: 0.00,
            purchase:0.00,
            loader: false,
            writers: this.props.writers,
            agentills: this.props.agentills,
            disable: false,
            formItems: {
                usertype:3, totalUnit:0, commission:0, unitfrom:'',  unito: '', transtype:1, 
                curuser: this.auth.getUser(), token: this.auth.getToken()
            }
        }
    }

    componentDidMount(){
        let writers = this.props.writers
        let formItems = this.state.formItems

        if(writers[0] !== undefined){
            formItems['unitfrom'] = writers[0].username
            formItems['unito'] = writers[0].username
        }

        this.setState({ formItems })
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            let writers = nextProps.writers
            let agentills = nextProps.agentills
            let formItems = this.state.formItems

            if(writers[0] !== undefined){
                formItems['unitfrom'] = writers[0].username
                formItems['unito'] = writers[0].username
            }

            this.setState({ writers, agentills, formItems })
        }
    }

    handleChange = (e) => {
        let unit = e.target.value
        let commision = ((100/70) * unit) - unit
        let purchase = unit

        this.setState({ commision, purchase })
    }

    handleType = (e) => {
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value

        this.setState({ formItems })
    }

    formSubmit = (e) => {
        this.setState({ loader:true, disable: true })
        e.preventDefault()

        let formItems = this.state.formItems

        //alert(JSON.stringify(this.state.agentills))
        if(formItems.unito == ''){
            formItems['unito'] = this.state.writers[0].username
        }
        if(formItems.unitfrom == ''){
            formItems['unitfrom'] = this.state.writers[0].username
        }

        if(formItems.unitfrom == formItems.unito){
            alert('Can\'t transfer to self...')
            return
        }
        
       
        formItems['totalUnit'] = parseFloat(this.state.purchase) + parseFloat(this.state.commision)
        formItems['commission'] = this.state.commision

        if(formItems.totalUnit > 0){
            this.setState({ loader:false, disable: false })

            let formData = this.auth.encryptData(JSON.stringify(formItems))
            axios(this.config.PostOptions(formData, 'web/web/unitransfer'))
            .then((res) => {
                alert(this.auth.decryptText(res.data))
                $('#btnchangecancel').click()
                this.props.getTransfers('within');
            }).catch((error)=>{alert(error); this.setState({ loader:false, disable:false })})
        }else{
            this.setState({ loader:false, disable:false });

            alert('Unit amount cannot be zero (0), please check and try again...');
            return
        }
    }

    loading = () => <div id="loader"></div>

    render(){
        let fromUnitill = this.state.agentills.filter(till => till.username == this.state.formItems.unitfrom && till.tillType == 1)
        let toUnitill = this.state.agentills.filter(till => till.username == this.state.formItems.unito && till.tillType == 1)
        let fromUnitBal = (fromUnitill[0]!==undefined) ? fromUnitill[0].openBal + fromUnitill[0].debits - fromUnitill[0].credits : 0
        let toUnitBal = (toUnitill[0]!==undefined) ? toUnitill[0].openBal + toUnitill[0].debits - toUnitill[0].credits : 0

        return(
            <div className="modal fade login-modal" id="changeUnitModal" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header"><h3>Transfer From a Writer to the other</h3></div>
                        <div className="modal-body">
                            <form id="frmUnitChange" className="smart-form" onSubmit={this.formSubmit}>
                                    <div className="row">
                                        <div className="col-xs-12 col-md-8">
                                            <label style={{width:'120px', marginRight:'10px'}} className='text-right'>From Writer: </label>
                                            <select name="unitfrom" value={this.state.formItems.unitfrom} onChange={this.handleType} style={{width:'150px'}}>
                                                {this.state.writers.map(writ => (
                                                    <option value={writ.username}>{writ.firstname} {writ.lastname}</option>
                                                ))}
                                            </select>
                                            <div className='text-right'><small>GH&cent; {numformat.format(fromUnitBal)}</small></div>
                                        </div>
                                        <div className="col-xs-12 col-md-8">
                                            <label style={{width:'120px', marginRight:'10px'}} className='text-right'>To Writer: </label>
                                            <select name="unito" value={this.state.formItems.unito} onChange={this.handleType} style={{width:'150px'}}>
                                                {this.state.writers.map(writ => (
                                                    <option value={writ.username}>{writ.firstname} {writ.lastname}</option>
                                                ))}
                                            </select>
                                            <div className='text-right'><small>GH&cent; {numformat.format(toUnitBal)}</small></div>
                                        </div>
                                        <div className="col-xs-12 col-md-8">
                                            <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Unit Amount:</label>
                                            <input type="number" min="0" name="purchase" placeholder="Unit Transfer" style={{width:'150px'}} 
                                                value={this.state.purchase} onChange={this.handleChange}/>
                                                <hr />
                                        </div>
                                        <div className="col-xs-12 col-md-8">
                                            <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Commission: </label><b>{numformat.format(this.state.commision)}</b>
                                        </div>
                                        <div className="col-xs-12 col-md-8">
                                            <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Total Unit: </label><b>
                                                {numformat.format(parseFloat(this.state.commision) + parseFloat(this.state.purchase))}
                                            </b>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button id="btnchangecancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                                <button type="submit" form="frmUnitChange" className="btn btn-primary" disabled={this.state.disable}>Exchange Unit</button>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}