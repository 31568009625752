import React, {Component} from 'react';
import './App.css';
import Auth from './common/Auth'
import Header from './common/Header';
import Footer from './common/Footer';
import Content from './common/Content';


class App extends Component{
  constructor(){
    super()

    this.auth = new Auth()
    this.state = {
      loc:'',
      pagenum: (this.auth.isAuth()) ? 6 : 1
    }
  }
  componentDidMount(){
  //  let loc=window.location.pathname
  //  let pagenum
  //  pagenum=(this.auth.isAuth()) ? 6 :''
  //  if(loc=="/Home" || loc=="/home" || loc=="/"){
  //   pagenum=(this.auth.isAuth()) ? 1 :1
  //  }else if(loc=="/Results" || loc=="/results"){
  //   pagenum=(this.auth.isAuth()) ? 2 :2
  //  }else if(loc=="/Games" || loc=="/games"){
  //   pagenum=(this.auth.isAuth()) ? 2 :3
  //  }else if(loc=="/About" || loc=="/about"){
  //   pagenum=(this.auth.isAuth()) ? 41 :41
  //  }else if(loc=="/Contacts" || loc=="/contacts"){
  //   pagenum=(this.auth.isAuth()) ? 5 :5
  //  }else if(loc=="/Profile" || loc=="/profile"){
  //   pagenum=(this.auth.isAuth()) ? 6 :1
  //  }else if(pagenum==""){
  //   pagenum=(this.auth.isAuth()) ? 6 :6
  //  }
  //   this.setState({loc,pagenum})
  }

  navClickHandler = (pagenum) => {
    
    // let about=["41","42","43","44","45"]
    // let game=["33","34","35","36","37"]
    // let newurl =  window.location.protocol + "//" + window.location.host +"/"
    // this.setState({ pagenum })
    // if(pagenum==1){
    //   newurl='/'
    //   window.history.replaceState(null, null, newurl);
    // }else if(pagenum==2){
    //   newurl='results';
    //   window.history.replaceState(null, null, newurl);
    // }else if(game.includes(pagenum)){
    //   newurl='games';
    //   window.history.replaceState(null, null, newurl);
    // }else if(about.includes(pagenum)){
      
    //   newurl='about';
    //   window.history.replaceState(null, null, newurl);
    // }else if(pagenum==5){
    //   newurl='contacts';
    //   window.history.replaceState(null, null, newurl);
    // }
    
   
  }

  render(){
    
      return (
        <div className="main-dark-version">
        <Header navClickHandler={this.navClickHandler}/>
        {/* <Content pagenum={this.state.pagenum} /> */}
        <Footer/>
      </div>
      )
  }
  
}

export default App;