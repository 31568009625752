import React, { Component, Fragment, useEffect, useState } from "react";
import ReactDOM from 'react-dom'
import Auth from "../common/Auth";
import Config from "../Config";
import Countdown from 'react-countdown-now'
import moment from "moment"
import $ from "jquery"
import _ from 'lodash'
import axios from 'axios'
import Chance from 'chance'

/**
 * 
 * Displays games page, used for staking lotto
 * 
 */
function Games(props) {
  const [gamecode, setGamecode] = useState(0);
  const [gamename, setGamename] = useState('');
  const [numbers, setNumbers] = useState([]);
  const [amount, setAmount] = useState(1);
  const [games, setGames] = useState([]);
  const [livegames, setLivegames] = useState([]);
  const [betting, setBetting] = useState([]);
  const [bettype, setBettype] = useState(1);
  const [mybettype, setMybettype] = useState({id:'', betname:'Direct 1', minNo:1});
  const [result, setResult] = useState(1);
  const [betItems, setBetItems] = useState({ id: 0, amount: 1, username: '', stakeNo: '', noline: 0, game: 0, bettype: 0, drawno: 0 });
  const [formItems, setFormItems] = useState({isdn: 'MTN', phone: '', voucher:'', amount:0, cart:[]});
  const [isChecked, setIsChecked] = useState(false);
  const [tempdata, setTempdata] = useState([]);
  const [selnumbers, setSelnumbers] = useState([]);
  const [seltemps, setSeltemps] = useState([]);
  const [selectedBets, setSelectedBets] = useState([]);
  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [noOfLines, setNoOfLines] = useState(1);

  const auth = new Auth();
  const config = new Config();
  const $http = config.DConnect();
  const { match: { params } } = props;

  const spanNum = {width: '46px', height: '46px', lineHeight: '48px', border: 'none', backgroundColor: 'black', marginTop: '10px', marginbottom: '10px', fontSize:'20px', fontWeight:'bold'}

  useEffect(() => {
    getLiveGames();
    
    let gamecode = params.gameid;
    let gamename = params.gamename;

    auth.unsetCart();
    setGamecode(gamecode);
    setGamename(gamename);

    resetBgColor();
    getGames();
    getBetting();
  },[]);

  const factorial = (num) => {
    var rval = 1;
    for (var i = 2; i <= num; i++)
      rval = rval * i;
    return rval;
  }
  
  const getGames = () => {
    $http.get('web/web/game')
      .then(res => {
        let games = auth.decryptData(res.data);

        setGames(games);
      }).catch((error) => { 
        alert((error.response !== undefined) ? error.response.data.message : error) 
      });
  }

  const getBetting = () => {
    $http.get('web/web/showonwebbetting')
      .then(res => {
        let betting = auth.decryptData(res.data)
        let mybet = betting.filter(bt => bt.id == betting[0].id)
        let mybettype = mybet[0]
        let selnumbers = []
        selnumbers.push(selNum(1,'num',<span>&nbsp;</span>))
        
        setBetting(betting);
        setMybettype(mybettype);
        setSelnumbers(selnumbers);
      }).catch((error) => { 
        alert((error.response !== undefined) ? error.response.data.message : error) 
      });
  }

  const getLiveGames = () => {
    $http.get('web/web/gamedetails/1')
      .then(res => {
        let livegames = auth.decryptData(res.data)
        
        setLivegames(livegames);
      })
  }

  const itemChoose = (num) => {
    let maxNo = mybettype.maxNo;

    let id = num - 1;
    if(numbers.includes(num) || tempdata.includes(num)){
      if(isChecked && tempdata.includes(num)){
        let index = tempdata.indexOf(num);
        if (index !== -1) tempdata.splice(index, 1);

        $("#btn_" + id).css("background-color", "#233d63")
        $("#btn_" + id).css("color", "white")
      }else if(!isChecked && numbers.includes(num)){
        let index = numbers.indexOf(num);
        if (index !== -1){ 
          numbers.splice(index, 1);

          $("#btn_" + id).css("background-color", "#233d63");
          $("#btn_" + id).css("color", "white");
        }
      }
    }else{
      if (numbers.length + tempdata.length >= maxNo) {
        alert("You can not choose more than " + maxNo + " number(s)...")
        return
      }

      if(isChecked){
        tempdata.push(num)
        $("#btn_" + id).css("background-color", "blue")
        $("#btn_" + id).css("color", "white")
      }else{
        numbers.push(num)
        $("#btn_" + id).css("background-color", "#ffc107")
        $("#btn_" + id).css("color", "#233d63")
      }
    }

    setNumbers(numbers);
    setTempdata(tempdata);
    
    setSelectedNumber(numbers, tempdata);
    getLines(numbers, tempdata);
  }

  const setSelectedNumber = (numbers, tempdata) =>{
    let selnumbers = [], seltemps = [];
    let mybet = betting.filter(bt => bt.id == bettype)
    let mybettype = mybet[0]

    let minNo = (mybettype.betname.indexOf('Against')!==-1) ? 3 : mybettype.minNo ;
    
    let limit = (numbers.length>minNo) ? numbers.length : minNo;
    for(var i=0; i<limit; i++){
      let num = (numbers[i] !== undefined) ? <span>{numbers[i]}</span> : <span>&nbsp;</span>;
      selnumbers.push(selNum(i,'num',num));
    }

    ReactDOM.render(selnumbers,document.getElementById('selnum'));

    if(bettype == 11){
      limit = (tempdata.length>minNo) ? tempdata.length : minNo;
      for(var i=0; i<limit; i++){
        let num = (tempdata[i] !== undefined) ? <span>{tempdata[i]}</span> : <span>&nbsp;</span>;
        seltemps.push(selNum(i,'num',num));
      }

      ReactDOM.render(seltemps,document.getElementById('selnumCon'));
    }
  }

  const clear = () => {
    let selnumbers = [], seltemps = [];

    numbers.map((snum, i) => {
      selnumbers.push(selNum(i,'num',<span>&nbsp;</span>));
    });
    ReactDOM.render(selnumbers,document.getElementById('selnum'));

    if(bettype == 11){
      tempdata.map((stemp, i) => {
        seltemps.push(selNum(i,'num',<span>&nbsp;</span>));
      });
      ReactDOM.render(seltemps,document.getElementById('selnumCon'));
    }
    
    setNumbers([]);
    setTempdata([]);
    setNoOfLines(0);
    
    resetBgColor();
  }

  const resetBgColor = () => {
    $("[id^=btn_]").css("background-color", "#233d63")
    $("[id^=btn_]").css("color", "white")
  }

  const quick = () => {
    let numbers = [], tempdata = [];
    let chance = new Chance();

    resetBgColor()

    let minNo = parseInt(mybettype.minNo)
    
    if(mybettype.betname.indexOf('Against')!==-1){
      let newQuick  = numbers = chance.unique(chance.natural, 6, {min:1, max:90})
      numbers = newQuick.slice(0,3)
      tempdata = newQuick.slice(3)
    }else{
      numbers = chance.unique(chance.natural, minNo, {min:1, max:90})
    }

    setNumbers(numbers);
    setTempdata(tempdata);

    getLines( numbers, tempdata )

    setSelectedNumber(numbers, tempdata)

    numbers.map(num => {
      var id = num - 1
      $("#btn_" + id).css("background-color", "#ffc107")
      $("#btn_" + id).css("color", "#233d63")
    })

    tempdata.map(num => {
      var id = num - 1
      $("#btn_" + id).css("background-color", "blue")
      $("#btn_" + id).css("color", "white")
    })
    
  }

  const getLines = (numbers, tempdata) => {
    let noOfLines = 1

    if (mybettype.betname.indexOf('Perm') !== -1) {
      let factor = mybettype.betname.match(/\d/g)

      noOfLines = permNumbers(factor, numbers.length)
    }else if(mybettype.betname.indexOf('All') !== -1){
      noOfLines = 89
    }else if(mybettype.betname.indexOf('Against') !== -1){
      noOfLines = numbers.length * tempdata.length
    }

    setNoOfLines(noOfLines);
  }

  const permNumbers = (perm, numcount) => {
    var db = numcount - perm
    var de = factorial(perm) * factorial(db)
    var nu = factorial(numcount);
    var result = (de != 0) ? (nu / de) : 0;

    return result
  }

  const handleChange = (e) => {
    let {name, value} = e.target;
    let amount = value;

    if(amount < mybettype.minStakeAmount){
      amount = mybettype.minStakeAmount;
    }else if(amount > mybettype.maxStakeAmount){
      amount = mybettype.maxStakeAmount;
    }
    
    setAmount(amount);
  }

  const handleChangeItem = (e) => {
    const {name, value} = e.target;
    
    setFormItems(prevState => ({ ...prevState, [name]:value }));
  }

  const handleChangebet = (e) => {
    clear();

    let bettype = e.target.value
    let mybet = betting.filter(bt => bt.id == bettype)
    let mybettype = mybet[0]

    let selnumbers = [], seltemps = []
    let minNo = (mybettype.betname.indexOf('Against')!==-1) ? 3 : mybettype.minNo ;
    for(var i=1; i<=minNo; i++){
      selnumbers.push(selNum(i,'num',<span>&nbsp;</span>));
    }

    if(mybettype.betname.indexOf('Against')!==-1){
      for(var i=1; i<=minNo; i++){
        seltemps.push(selNum(i,'temp',<span>&nbsp;</span>));
      }
    }

    ReactDOM.render(selnumbers,document.getElementById('selnum'));

    setBettype(bettype);
    setMybettype(mybettype);
    setSelnumbers(selnumbers);
    setSeltemps(seltemps);
    setIsChecked(false);
  }

  const toggleChange = () => setIsChecked(!isChecked);

  const formatGame = (cell) => {
    let game = games.filter(gm => gm.id == cell);
    let gamename = (game[0] !== undefined) ? game[0].gamename : cell;
    let ext = (game[0] !== undefined) ? game[0].filex : 'png';

    return [gamename, ext];
  }

  const gameFormat = (cell) => {
    let game = livegames.filter(gm => gm.gamecode == cell);
    let ddate = (game != '') ? game[0].drawDate : new Date();

    return ddate;
  }

  const addtoCart = () => {
    let curgame = livegames.filter(gm => gm.gamecode == gamecode);
    let minNo = parseInt(mybettype.minNo);

    if(curgame[0] == undefined || moment(curgame[0].drawDate).isBefore(moment())){
      alert("Game is not active")
      return
    } else if (numbers.length < minNo) {
      alert("You can only stake a minimum of " + minNo + " numbers")
      return
    } else if (amount == '' || amount <= 0) {
      alert("Stake Amount must be greater than Zero (0)")
      return
    }

    let stakeNum = numbers.join(' - ')
    if(tempdata.length > 0){
      stakeNum += ' ~ ' + tempdata.join(' - ')
    }
    
    betItems["amount"] = amount
    betItems["username"] = "webportal"
    betItems["stakeNo"] = stakeNum
    betItems["noline"] = noOfLines
    betItems["game"] = gamecode
    betItems["bettype"] = bettype
    betItems["drawno"] = curgame[0].drawno

    let cart = (auth.getCart() == null) ? [] : auth.getCart();
    cart.push(betItems);
    auth.setCart(JSON.stringify(cart));
    let tAmount = totalAmount + (betItems.amount * noOfLines);

    selectedBets.push(betItem(betItems, selectedBets.length, numbers));

    setCart(cart);
    setSelectedBets(selectedBets);
    setTotalAmount(tAmount);

    clear();
  }

  const deleteBet = (itm) => {
    selectedBets.splice(itm, 1);
    cart.splice(itm, 1);

    let totalAmount = _.reduce(cart, (total, itm) => {return total + (itm.noline * itm.amount)},0)

    setSelectedBets(selectedBets);
    setCart(cart);
    setTotalAmount(totalAmount);
    

    auth.setCart(JSON.stringify(cart));
  }

  const betItem = (betItems, index, numbers) => {
    let itm = <li id={'betItem_' + index}>
      <div className="bet-group">
        <h3>{mybettype.betname} <span className="close" onClick={() => deleteBet(index)}>x</span></h3>
        <div className="betNum" style={{fontWeight:'bolder'}}>{numbers.map(num => <span>{num}</span>)}</div>
        {betItems.bettype == 11 && <Fragment>
          <div className="text-center"><i>Against</i></div>
          <div className="againstNum" style={{fontWeight:'bolder'}}>{numbers.map(num => <span>{num}</span>)}</div>
        </Fragment>}
        <div className="btn-group btn-group-xs btn-group-justified" style={{width:'100%'}}>
          <span className="btn btn-xs btn-default"><b>Lines</b><br />{betItems.noline}</span>
          <span className="btn btn-xs btn-default"><b>Unit</b><br />{betItems.amount}</span>
          <span className="btn btn-xs btn-default"><b>Total (&cent;)</b><br />{betItems.noline * betItems.amount}</span>
        </div>
      </div>
    </li>;

    return itm;
  }

  const selNum = (cnt, area, value) => {
    return <li id={'sel' + area + cnt} key={cnt} className="text-center" style={spanNum}>{value}</li>
  }

  const formSubmit = (e) => {
    e.preventDefault();
    
    if(cart == undefined || cart.length <=0){
      alert("No bets added to ticket...")
      return
    }else if(formItems.phone.trim() == ''){
      alert("Please enter a registered mobile money number...")
      return
    }else if(formItems.isdn == 'VDF' && formItems.voucher.trim() == ''){
      alert("Please generate a Vodafone voucher code and enter it...")
      return
    }

    formItems['cart'] = cart
    formItems['amount'] = totalAmount

    let formData =auth.encryptData(JSON.stringify(formItems))
    axios(config.PostOptions(formData, 'web/web/stakegame/momo'))
    .then((res) => {
      let resdata=auth.decryptText(res.data)
      alert(JSON.stringify(resdata))

      if(resdata == 'Complete the transaction on your phone...'){
        auth.unsetCart()
        window.location.replace('/')
      }
    }).catch(error => {auth.unsetCart()});
  }
    
  let str = ''
  if(mybettype.betname !== undefined){
    str = (mybettype.betname.indexOf('Direct') !== -1 || mybettype.betname.indexOf('All') !== -1) ? 'exactly' : 'a minimum of'
  }
    
  return (
    <div>
      <section className="inner-page-banner has_bg_image" data-background="/assets/images/inner-page-bg.jpg" style={{ backgroundImage: 'url(/assets/images/inner-page-bg.jpg)' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-page-banner-area">
                <h1 className="page-title">{params.gamename.replace('_', " ")}</h1>
                <nav aria-label="breadcrumb" className="page-header-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">game</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cart-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="cart-area d-flex">
                <div className="col-lg-8">
                  <div className="single-header d-flex justify-content-between">
                    <div className="left d-flex">
                      <div className="icon">
                        <img src={config.BaseURL() + 'gamefiles/' + params.gamename + '.' + formatGame(gamename)[1]} style={{ width: '64px' }} />
                      </div>
                      <div className="content">
                        <h3 className="title">{params.gamename.replace('_', " ")}</h3>
                        <span className="amount"><Countdown date={gameFormat(params.gameid)} /></span>
                      </div>
                    </div>
                  </div>
                  <div className="single-body">
                    <div className="single-body-inner d-flex">
                      <div className="play-card">
                        <div className="play-card-inner text-center">
                          <div className="play-card-header">
                            <div className="header-btn-area clearfix">
                              <button type="button" id="quick-pick1" className="pull-left" onClick={() => quick()}>quick pick</button>
                              <button type="button" id="clear-pick1" className="pull-left" onClick={() => clear()}>clear all</button>
                              <select className="pull-right form-control" onChange={handleChangebet} value={bettype} style={{ width: "20%" }}>
                                {betting.map(bt => (
                                  <option value={bt.id}>{bt.betname}</option>
                                ))}
                              </select>
                              {mybettype.betname !== undefined && mybettype.betname.indexOf('Against') !== -1 &&
                                <div className="custom-control custom-switch pull-right mr-5">
                                  <input type="checkbox" className="custom-control-input" id="switch1" checked={isChecked}
                                    onChange={toggleChange} name="isChecked" />
                                  <label className="custom-control-label" for="switch1">Select {(isChecked) ? 'Against' : 'Bankers'}</label>
                                </div>
                              }
                              
                            </div>
                          </div>
                          <div className="play-card-body" id="play-card-body">
                              <div className="text-center">Select {str} {mybettype.minNo} Number(s)</div>
                              <ul className="number-list">
                                {[...Array(90)].map((x, i) => {
                                  let num = (i<9) ? `0${i+1}` : i+1;
                                  return <li id={"btn_" + i} className="btnNums" style={{fontWeight:'bolder'}} onClick={() => itemChoose(num)}>{num}</li>
                                })}
                              </ul>
                          </div>
                          <div className="play-card-footer">
                            <p className="play-card-footer-text">Selected Numbers:</p>
                            <div className="selected-numbers">
                              <ul id="selnum" className="number-list"></ul>
                              {bettype == 11 && 
                              <>
                                <div className="text-white"><i>Against</i></div>
                                <ul id="selnumCon" className="number-list"></ul>
                              </>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="single-footer d-flex justify-content-between">
                    <div className="left">
                      <span style={{width:'300px'}}>
                        Line amount (GH&cent;):&nbsp;
                        <input type="number" min={mybettype.minStakeAmount} max={mybettype.maxStakeAmount} step="0.1" onChange={handleChange} style={{ width: '60px', display:'inline' }} name="amount" value={amount} />
                        &nbsp; x {noOfLines} line{(noOfLines <= 1) ? '':'s'}
                        </span>
                        Stake Limit(GH&cent;): <b>{mybettype.minStakeAmount}</b> to <b>{mybettype.maxStakeAmount}</b>
                    </div>
                    <div className="right d-flex justify-content-between">
                      <div className="card-cart-btn-area">
                        <button onClick={addtoCart} className="single-cart-btn">
                          <span className="single-cart-amount">GH&cent; {noOfLines * amount}</span>
                          add to ticket
                      </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4" >
                  <div className="single-header d-flex justify-content-between mb-4">
                    <div className="left d-flex">
                      <div className="icon"></div>
                      <div className="content">
                        <h3 className="title">&nbsp;</h3>
                        <span className="amount">&nbsp;</span>
                      </div>
                    </div>
                  </div>
                  <div className="cart-summary-area">
                    <h3 className="summary-area-title">Ticket Details</h3>
                    <ul style={{maxHeight:'500px',overflow:'auto'}}>{selectedBets.map(sbet => sbet)}</ul>
                    <form id="frmCart" method="POST" onSubmit={formSubmit}>
                      <div className="total-amount">
                        <span className="title">total payment</span>
                        <span className="amount">Gh&cent;  {totalAmount}</span>
                        <hr className="hrsp" style={{borderColor:'white'}} />
                        <span className="title">Mobile Money Number</span>
                        <div className="btn btn-group btn-group-xs">
                          <input type="text" className="bg-white" name="phone" id="phone" 
                          value={formItems.phone} placeholder="e.g. 0553671948" onChange={handleChangeItem} />
                          <select id="isdn" name="isdn" className="btn btn-xs btn-default bg-white" 
                          onChange={handleChangeItem} value={formItems.isdn}>
                            <option value="MTN">MTN</option>
                            <option value="ATL">AirtelTigo</option>
                            <option value="VDF">Vodafone</option>
                          </select>
                        </div>
                        {formItems.isdn === 'VDF' &&
                        <div className="btn btn-group btn-group-xs">
                          <input type="text" className="btn btn-xs btn-default bg-white" name="voucher" id="voucher" 
                          value={formItems.voucher} placeholder="Voucher code" onChange={handleChangeItem} />
                        </div>}
                        <button type="submit" className="payment-btn bg-warning text-primary"><b>Play now!</b></button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Games;