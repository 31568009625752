import React, { Component } from "react";
import Auth from "../common/Auth";
import Config from "../Config";

export default class Promotion extends Component {
  constructor() {
    super();
    this.auth = new Auth();
    this.config = new Config();
    this.$http = this.config.DConnect();
    this.state = {};
  }

  render() {
    return (
      <div>
        
      </div>
    )
  }
}