import React, { Component, Fragment } from "react"
import ReactDOM from 'react-dom'
import Auth from "../common/Auth"
import Config from "../Config"
import _ from 'lodash'
import $ from 'jquery'
import { FlipClock } from 'reactflipclock-js'
import Countdown from 'react-countdown-now'
import moment from "moment"
import { Link, BrowserRouter as Router } from "react-router-dom"
import {Carousel} from 'react-bootstrap';

/**
 * 
 * Displays home page
 * 
 */
export default class Index extends Component {
  constructor(props) {
    super(props)

    this.auth = new Auth()
    this.config = new Config()
    this.$http = this.config.DConnect()

    this.state = {
      content: [],
      drawnum: [],
      games: [],
      livegames: [],
      curTime: 0,
      flash:{id:0, title:''},
      flashImages: []
    }
  }

  componentDidMount() {
    this.getContent()
    this.getWinNumbers()

  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps !== this.props) {
      this.getWinNumbers()
    }
  }

  getContent = () => {
    this.$http.get('web/web/portal/1')
      .then(res => {
        let content = this.auth.decryptData(res.data)

        let flash = content.filter(ct => ct.category===1 && ct.categorySub===0);
        if(flash.length>0){
          this.setState({ content, flash: flash[0] });
          this.getFlashImages(flash[0].id);
        }else{
          this.setState({ content });
        }
        this.showPageContent(content)
      }).catch((error) => { console.log(error) })
  }

  getFlashImages =(id) => {
    this.$http.get(`web/web/multimedia/portal/${id}`)
    .then(res => {
      let flashImages = this.auth.decryptData(res.data);

      this.setState({ flashImages });
    }).catch((error) => { console.log(error) })
  }

  getWinNumbers = () => {
    this.$http.get('web/web/game')
      .then(res => {
        let games = this.auth.decryptData(res.data);
        this.setState({ games })

        this.$http.get('web/web/drawnum/5')
          .then(res => {
            let resdata = this.auth.decryptData(res.data);
            let drawnum = [];

            resdata.map(rdata => {
              let game = this.state.games.filter(gm => gm.id == rdata.gamecode && gm.showOnWeb == 1);
              if(game.length>0){
                drawnum.push(rdata);
              }
            }).catch((error) => { console.log(error) });

            this.setState({ drawnum })

            this.getLiveGames();
          }).catch((error) => { console.log(error) });
      })
  }

  getLiveGames = () => {
    this.$http.get('web/web/gamedetails/1')
      .then(res => {
        let resdata = this.auth.decryptData(res.data);
        let livegames = [];

        resdata.map(rdata => {
          let game = this.state.games.filter(gm => gm.id == rdata.gamecode && gm.showOnWeb == 1);
          if(game.length>0){
            livegames.push(rdata);
          }
        });

        let curTime = (livegames[0] !== undefined && moment(livegames[0].drawDate) > moment()) ? moment(livegames[0].drawDate).diff(moment(), 'seconds') : 0
        if(livegames[0] !== undefined){
        let latest = this.formatGame(livegames[0].gamecode)
        let itm = <Fragment>
          <img src={this.config.BaseURL() + 'gamefiles/' + latest[0].replace(' ','_') + '.' + latest[1]} alt="" style={{width:'100px'}} /><br />  {latest[0]} <small className="badge badge-success blink">Live</small>
        </Fragment>
        ReactDOM.render(itm, document.getElementById('latest'))
        }
        this.setState({ livegames, curTime })
      }).catch((error) => { console.log(error) })
  }

  formatGame = (cell) => {
    let game = this.state.games.filter(gm => gm.id == cell)
    let gamename = (game[0] !== undefined) ? game[0].gamename : cell
    let ext = (game[0] !== undefined) ? game[0].filex : 'png'

    return [gamename, ext]
  }

  showPageContent = (content) => {
    let groupContent = _.groupBy(content, 'category')

    let txtB = (groupContent['11']!== undefined && groupContent['11'].length>0) ? groupContent['11'][0] : '';
    let banner = <Fragment>
      <h1 className="title">{txtB.title}</h1>
      <p>{txtB.content}</p>
    </Fragment>
    //ReactDOM.render(banner, document.getElementById('bannerCon'))

    let txtC = (groupContent['12']!== undefined && groupContent['12'].length>0) ? groupContent['12'][0] : '';
    let curGame = <div className="timer-content">
      <h3 id="latest" className="title text-center"></h3>
    </div>
    ReactDOM.render(curGame, document.getElementById('curgameCon'))
    ReactDOM.render(<p className="text-center"><b className="text-success">{txtC.title}</b><br /> {txtC.content}</p>, 
    document.getElementById('curgameContent'))

    let txtR = (groupContent['13']!== undefined && groupContent['13'].length>0) ? groupContent['13'][0] : '' ;
    let gameResult = <div className="section-header text-center">
      <h2 className="section-title">{txtR.title}</h2>
      <p>{txtR.content}</p>
    </div>
    ReactDOM.render(gameResult, document.getElementById('gameresultCon'))

    let txtT = (groupContent['14']!== undefined && groupContent['14'].length>0) ? groupContent['14'][0] : '' ;
    let buyTicket = <div className="section-header text-center">
      <h2 className="section-title">{txtT.title}</h2>
      <p>{txtT.content}</p>
    </div>
    ReactDOM.render(buyTicket, document.getElementById('buyticketCon'))

    let txtP = (groupContent['15']!== undefined && groupContent['15'].length>0) ? groupContent['15'][0] : '' ;
    let payMethod = <div className="section-header text-center">
      <h2 className="section-title">{txtP.title}</h2>
      <p>{txtP.content}</p>
    </div>
    ReactDOM.render(payMethod, document.getElementById('paymethodCon'))
  }

  render() {
    let {flashImages} = this.state
    let lgame = this.state.livegames[0]
    let curGameLink = "#"
    if (lgame !== undefined && lgame.id !== undefined) {
      let gameName = this.formatGame(lgame.gamecode)
      curGameLink = "/game/" + lgame.gamecode + "/" + gameName[0].replace(' ', "_") + "/"
    }
    let resultLink = "/result"

    return (
      <div>
        <section className="">
        <Carousel>
          {flashImages.map(fimg => (
            <Carousel.Item>
              <img src={`assets/images/${fimg.filename}`} alt="Banner" style={{width:'100%'}} />
            </Carousel.Item>
          ))}
        </Carousel>
        </section>
        <section className="lottery-timer-section">
          <div className="container">
            <div className="row justify-content-between">
              <div id="curgameCon" className="col-xl-4"></div>
              <div className="col-xl-6 text-center">
                <div className="timer-part"><div className="clock" id="clock">
                  <FlipClock time={this.state.curTime} clockFace="DailyCounter" countDown="true" />
                </div></div>
              </div>
              <div className="col-xl-2">
                <div className="link-area text-center">
                  <Link to={curGameLink} className="border-btn bg-success text-white">Buy Ticket Now!</Link>
                </div>
              </div>
              <div id="curgameContent"></div>
            </div>
          </div>
        </section>

        <section className="online-ticket-section section-padding has_bg_image" data-background="assets/images/bg-two.jpg" style={{ backgroundImage: 'url(assets/images/bg-two.jpg)' }}>
          <div className="container">
            <div className="row justify-content-center">
              <div id="buyticketCon" className="col-lg-8"></div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="online-ticket-table-part">
                  <div className="online-ticket-table">
                    <h3 className="block-title">Play game online</h3>
                    <div className="online-ticket-table-wrapper">
                      <table>
                        <thead>
                          <tr>
                            <th className="name">lottery</th>
                            <th className="draw-time">time to draw</th>
                            <th className="sold-num">tickets sold</th>
                            <th className="status">status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.livegames.slice(0, 5).map(lgame => {
                            let gamename = this.formatGame(lgame.gamecode)
                            let pic = gamename[0].toString().replace(' ', '_')
                            let link = "/game/" + lgame.gamecode + "/" + pic + "/"

                            return (
                              <tr>
                                <td>
                                  <div className="winner-details"><img src={this.config.BaseURL() + 'gamefiles/' + pic + '.' + gamename[1]} style={{ width: '64px' }} /><span className="winner-name">{gamename[0]} (Event: {lgame.drawno})</span></div>
                                </td>
                                <td><Countdown date={lgame.drawDate} /></td>
                                <td>
                                  <div className="progressbar" data-perc="50%">
                                    <div className="bar"></div>
                                    <span className="label">50</span>
                                  </div>
                                </td>
                                <td>
                                  {(lgame.status == 1) &&
                                    <Link to={link} className="cmn-btn">buy ticket</Link>
                                  }
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="col-lg-12 text-center">
                <a href="#" className="text-btn">view all lotteries</a>
                        </div>*/}
            </div>
          </div>
        </section>

        <section className="lottery-result-section section-padding has_bg_image" data-background="assets/images/bg-one.jpg" style={{ backgroundImage: 'url(assets/images/bg-one.jpg)' }}>
          <div className="container">
            <div className="row justify-content-center">
              <div id="gameresultCon" className="col-lg-7"></div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="lottery-winning-num-part">
                  <div className="lottery-winning-num-table">
                    <h3 className="block-title">lottery winning numbers</h3>
                    <div className="lottery-winning-table">
                      <table>
                        <thead>
                          <tr>
                            <th className="name">lottery</th>
                            <th className="date">draw date</th>
                            <th className="status">winning numbers</th>
                            <th className="status">machine numbers</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.drawnum.map(dnum => {
                            let winum = dnum.drawNumbers.split('-')
                            let manum = dnum.machineNumbers.split('-')
                            let gamename = this.formatGame(dnum.gamecode)
                            let pic = gamename[0].split(' ').join('_')
                            return (<tr>
                              <td><div className="winner-details"><img src={this.config.BaseURL() + 'gamefiles/' + pic + '.' + gamename[1]} alt="game logo" style={{ width: '64px' }} /><span className="winner-name">{gamename[0]} (Event: {dnum.drawno})</span></div></td>
                              <td><span className="winning-date">{moment(dnum.drawDate).format('D MMM, YYYY')}</span></td>
                              <td>
                                <ul className="number-list">{winum.map(wn => (<li className="active">{wn}</li>))}</ul>
                              </td>
                              <td>
                                <ul className="number-list">{manum.map(mn => (<li>{mn}</li>))}</ul>
                              </td>
                            </tr>)
                          })}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 text-center">
                <Link to={resultLink} className="text-btn">see all result</Link>
              </div>
            </div>
          </div>
        </section>



        <section className="payment-method-section section-padding border-top">
          <div className="container">
            <div className="row justify-content-center">
              <div id="paymethodCon" className="col-lg-6"></div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="payment-method-area d-flex">
                  <div className="payment-item">
                    <a href="#"><img src="assets/images/payment-methods/airtel-tigo-money-logo.png" alt="payment-method-image" /></a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

      </div>

    )
  }
}
