/**
 * 
 * The web app menus
 * 
 */
export default function Menu(){
    let menu = [
        
        {code:'1', name:'Home'},
        {code:'2', name:'Results'},
        {code:'3', name:'Games', submenu:[]},
        {code:'4', name:'About', submenu:[
            {code:'41', name:'Vision Lotto'},
            {code:'42', name:'News'},
            {code:'43', name:'How To Play'},
            {code:'44', name:'FAQ'},
            {code:'45', name:'Disclaimer'},
            {code:'46', name:'Terms & Conditions'}
        ]},
        {code:'5', name:'Contacts'}
    ]

    return menu
}