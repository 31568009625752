import Axios from 'axios';
import React, {useState, useEffect} from 'react';
import Auth from '../common/Auth';
import Config from '../Config';
import moment from 'moment';
import $ from 'jquery';

/**
 * 
 * Changes a user password
 * 
 */
function PasswordForm(props){
    const config = new Config();
    const auth = new Auth();
    const [formItems, setFormItems] = useState({username:props.user.username, oldpassword:'', newpassword:'', retypepass:'',
    token:auth.getToken(), modifiedby:auth.getUser(), curuser:auth.getUser(), modifiedon:new Date()});
    const [isDisabled, setIsDisabled] = useState(false);

    const handleChange = (e) => {
        e.preventDefault();

        let {name, value} = e.target;

        setFormItems(prevState => ({
            ...prevState, [name]:value
        }));
    }

    const updatePassword = (e) => {
        e.preventDefault();

        if(formItems.newpassword !== formItems.retypepass){
            alert('Password mismatched...');
            return '';
        }

        setIsDisabled(true);
        formItems['isNew'] = false;
        formItems['modifiedby'] = auth.getUser();
        formItems['modifiedon'] = moment();
        let formData = auth.encryptData(JSON.stringify(formItems));
        Axios(config.PostOptions(formData, 'web/web/changepass'))
        .then((res) => {
                alert(auth.decryptText(res.data));

                setIsDisabled(false);

                $('#btnMyPassword').click();
        }).catch((error)=>{alert(error);setIsDisabled(false);});
    }


    return(
        <div class="modal fade registration-modal" id="passwordModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div className="modal-header">
                        <h3>Change Paassword</h3>
                    </div>
                    <div class="modal-body">
                        <form class="registration-form cmn-frm">
                            <div class="frm-group">
                                <label>Old Password</label>
                                <input type="password" name="oldpassword" placeholder="Old Password" required 
                                value={formItems.oldpassword} onChange={handleChange}/>
                                <i class="fa fa-lock"></i>
                            </div>
                            <div class="frm-group">
                                <label>New Password</label>
                                <input type="password" name="newpassword" placeholder="New Password" required 
                                value={formItems.newpassword} onChange={handleChange}/>
                                <i class="fa fa-lock"></i>
                            </div>
                            <div class="frm-group">
                                <label>Retype Password</label>
                                <input type="password" name="retypepass" placeholder="Retype Password" required 
                                                value={formItems.retypepass} onChange={handleChange}/>
                                <i class="fa fa-lock"></i>
                            </div>
                            <div class="frm-group pull-right cmn-btn" >
                                <button id="btnMyPassword" type="button" style={{color:"white"}} className="btn btn-default" data-dismiss="modal">Cancel</button>
                                <button className="btn " style={{color:"white"}} type="submit" disabled={isDisabled} onClick={updatePassword} id="password_submit">Change Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordForm;