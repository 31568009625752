import React, { Component, useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import Auth from "../common/Auth";
import Config from "../Config";
import _ from 'lodash';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Axios from "axios";

/**
 * 
 * Displays users wins
 * 
 */
function Wins(props){

    const auth = new Auth();
    const config = new Config();

    const [winData, setWinData] = useState([]);
    const [groupedData, setGroupedData] = useState({});
    const [games, setGames] = useState([]);
    const [loader, setLoader] = useState(false);
    const [retailers, setRetailers] = useState([]);
    const [formItems, setFormItems] = useState({ username: auth.getUser(), game:0, startDate: moment().format('YYYY-MM-DD'), curuser: auth.getUser(), token: auth.getToken()})

    const amountformat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

    useEffect(() => {

        getRetailer();

        getGame(moment().format('YYYY-MM-DD'));
    },[]);

    const getRetailer = () => {
        config.DConnect().get('admin/web/' + auth.getUser() + '/' + auth.getToken() + '/user/5').then(res => {
            if (isNaN(res.data)) {
                let retailers = auth.decryptData(res.data);
                
                setRetailers(retailers);
            } else {
                auth.doAlert(parseInt(res.data));
            }
        });
    }

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setFormItems(prevState => ({...prevState, [name]:value}));

        setWinData([]);
        setGroupedData([]);

        if(name == 'startDate'){
            getGame(value);
        }
    }

    const displayData = () => {
        let fItems = {...formItems};

        setLoader(true);

        let formData = auth.encryptData(JSON.stringify(fItems))
        Axios(config.PostOptions(formData, 'web/web/winnings'))
        .then((res) => {
            if (isNaN(res.data)) {
                let resdata = auth.decryptData(res.data),
                    winsData = resdata.filter(rdata => rdata.agent === auth.getUser()),
                    unpaidWins = winsData.filter(wdata => wdata.agentPaid && !wdata.retailerPaid),
                    groupedData = _.groupBy(unpaidWins, 'retailer');

                console.log(JSON.stringify(winsData));
                setWinData(unpaidWins);
                setGroupedData(groupedData);
            } else {
                auth.doAlert(parseInt(res.data));
            } 
            setLoader(false);          
        }).catch((error)=>{alert(error);setLoader(false);  });
    }

    const getGame = (drawDate) =>{
        config.DConnect().get('game/web/' + auth.getUser() + '/' + auth.getToken() + '/gamebydrawdate/' + encodeURIComponent(drawDate))
        .then(res => {
            let games = auth.decryptData(res.data);
            
            setGames(games);
        })
    }

    const getGamename=(code)=>{
        let game = games.filter(game => game.id === code)
        return (game.length > 0)?game[0].gamename:"";
    }

    const retailerFormat = (cell, row) => {
        let retailname = retailers.filter(retailer => retailer.username === cell);

        return (retailname.length > 0 ) ? `${retailname[0].firstname} ${retailname[0].lastname} (${cell})` : cell;
    };
    const curFormat = (cell, row) => amountformat.format(cell);

    const makePayment = async (retailer) => {
        if (window.confirm("This will send money to Retailer(s)... Do you want to continue?") == true) {
            setLoader(true);  

            let formData = {'winIds':[], 'agent':retailer, 'createdBy': auth.getUser()}, 
                winIds = [];
            
            if(retailer == 0){
                winIds = winData.map(wdata => wdata.id);
                
            }else{
                winIds = groupedData[`${retailer}`].map(wdata => wdata.id);
            }

            formData.winIds = winIds;

            await Axios(config.PostOptions(auth.encryptData(JSON.stringify(formData)), 
            'admin/web/agent-retailer/payment')) .then((res) => {
                if (isNaN(res.data)) {
                    setWinData([]);
                    setGroupedData([]);

                    let resdata = auth.decryptText(res.data);

                    alert(JSON.stringify(resdata));
                } else {
                    auth.doAlert(parseInt(res.data))
                }  
                
                setLoader(false);
                
                displayData();
            }).catch((error)=>{alert(error);setLoader(false);});
        }
    }

    if(loader){
        return <div id="loader">Loading please wait...</div>;
    }

    return (
        <div class="blog-details-wrapper">
            <div class="content">
                <h3 class="post-title">My Wins </h3>
                <hr />
                <div class="content">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="btn-group btn-group-sm pull-right">
                                <button className="btn btn-default">Date: </button>
                                <input type="date" id="startDate" name="startDate" onChange={handleChange} value={moment(formItems.startDate).format('YYYY-MM-DD')} />
                                <button className="btn btn-default">Game: </button>
                                <select id="game" name="game" onChange={handleChange} value={formItems.game} className="btn btn-default" style={{ width: '150px', height:'30px' }}>
                                    <option value=''>-- Select Game --</option>
                                    {games.map(gm =>
                                        <option value={gm.id}>{gm.gamename}</option>
                                    )}
                                </select>
                                <button onClick={displayData} className="btn btn-info">Display</button>
                            </div>
                            <div className="clearfix"></div>
                            <hr />
                        </div>
                        <div className="col-lg-12">
                            <table className="table table-striped table-condensed">
                                <thead>
                                    <tr>
                                        <th>Retailer Name</th>
                                        <th style={{textAlign:'right',width:'150px'}}>Total Wins</th>
                                        <th style={{textAlign:'right',width:'150px'}}><button className="btn btn-sm btn-danger" onClick={() => makePayment(0)}><i className="fa fa-money" /> Pay All</button></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(groupedData).map((key, idx) => 
                                    <tr>
                                        <td>{retailerFormat(key, groupedData[`${key}`])}</td>
                                        <td style={{textAlign:'right',width:'150px'}}>{numformat.format(_.sumBy(groupedData[`${key}`],'winAmount'))}</td>
                                        <td style={{textAlign:'right',width:'150px'}}>
                                            {groupedData[`${key}`][0].status < 2 &&
                                            <button className="btn btn-sm btn-warning" onClick={() => makePayment(key)}><i className="fa fa-money" /> Pay</button>}
                                        </td>
                                    </tr>)}
                                    <tr>
                                        <th>Total</th>
                                        <th style={{textAlign:'right',width:'150px'}}>{numformat.format(_.sumBy(winData,'winAmount'))}</th>
                                        <th style={{textAlign:'right',width:'150px'}}>&nbsp;</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Wins;