import axios from 'axios'
import Auth from './common/Auth'

export default class Config{ 

    DConnect(){
        return axios.create({
            baseURL: this.BaseURL() +'pmsresource/',
            timeout: 100000, 
            headers: {
              'Content-Type': 'application/json'
            }
        })
    }

    PostOptions(itms, endpoint){ 
        return {
            method: 'post',
            url: this.BaseURL() + 'pmsresource/' + endpoint,
            data: itms,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }
    }

    GetImage(itms, endpoint){ 
        return {
            method: 'post',
            url: this.BaseURL() + 'pmsresource/' + endpoint,
            data: itms,
            headers: {
                responseType: 'arraybuffer'
            }
        }
    }

    PostMulti(itms, endpoint){
        return {
            method: 'post',
            url: this.BaseURL() + 'pmsresource/' + endpoint,
            data: itms
        }
    }

    ImagePath(folder){
        let auth = new Auth()
        return(this.BaseURL() + folder )
    }


    BaseURL(){
        // return 'https://alphalottogh.com:8443/';
        // return 'https://test.alphalottogh.com:8443/';
        return 'https://backend.vision2000lotto.com:8443/vision/';
        // return 'http://alphalottogh.com:8081/test/';
        // return 'https://myalphaonline.com:8443/alpha/';
        // return 'http://myalphaonline.com:8081/test/';
        // return 'http://localhost:8085/alpha_project/';
    }
}