import React, { Component } from "react"
import ReactDOM from 'react-dom'
import Auth from "../common/Auth"
import Config from "../Config"
import _ from 'lodash'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from 'moment'
import PurchaseUnitForm from "./PurchaseUnitForm"
import TransferUnitForm from "./TransferUnitForm"
import ChangeUnitForm from "./ChangeUnitForm"

var amountformat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
var naration = {'2':'Reversal of Credit from ','-2':'Transfer of Credit to '}

/**
 * 
 * Main page that allows you to transfer, purchase and change unit
 * 
 */
export default class Credits extends Component{
    constructor(props) {
        super(props)
    
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            roleid: this.props.roleid,
            transactions: [],
            commissions: [],
            writers: [],
            transferdata: {out:[], within:[]},
            agentills: [],
            unit: {id:0, openBal:0, closeBal:0, debits:0, credits:0}
        }
    }

    componentDidMount(){
        this.getPurchased();

        this.getWriters();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            let roleid = nextProps.roleid

            this.setState({roleid})

            this.getPurchased()

            this.getWriters()
        }
    }

    getWriters = () => {
        this.$http.get('web/web/' + this.auth.getUser() + '/writers')
        .then(res => {
            let writers = this.auth.decryptData(res.data)

            this.setState({ writers })
        })
    }

    getPurchased = () => {
        this.$http.get('web/web/' + this.auth.getUser() + '/journal')
        .then(res => {
            let journal = this.auth.decryptData(res.data)

            let transactions = journal.filter(journ => (journ.drgl.startsWith('102') && journ.crgl.startsWith('403')) 
            || (journ.crgl.startsWith('102') && journ.drgl.startsWith('403')))

            let commissions = journal.filter(journ => (journ.drgl.startsWith('502') && journ.crgl.startsWith('102')) 
            || (journ.crgl.startsWith('502') && journ.drgl.startsWith('102')))

            this.setState({ transactions, commissions})

            this.$http.get('web/web/' + this.auth.getUser() + '/usertill')
            .then(res => {
                let usertill = this.auth.decryptData(res.data)

                let unit = usertill.filter(rdata => rdata.tillType == '1' && rdata.status)

                this.setState({ unit: unit[0] })

                this.getTransfers('out')

                this.getTransfers('within')

                this.getAgentTills()
            })
        }).catch((error)=>{alert(error)})
    }

    getTransfers = (transtype) => {
        this.$http.get('web/web/' + this.auth.getUser() + '/transferunit/' + transtype)
        .then(res => {
            let resdata = this.auth.decryptData(res.data)
            let transferdata = this.state.transferdata

            transferdata[transtype] = resdata

            this.setState({ transferdata })
        })
    }

    getAgentTills = () => {
        this.$http.get('web/web/' + this.auth.getUser() + '/tillagent')
        .then(res => {
            let agentills = this.auth.decryptData(res.data)

            this.setState({ agentills })
        })
    }

    commissionFormat = (cell, row) => {
        let commission = this.state.commissions.filter(com => com.accountid == row.accountid && com.createdon == row.createdon)
        let comAmount = (commission[0] !== undefined) ? commission[0].amount : 0

        return amountformat.format(comAmount);
    }

    dateFormat = (cell, row) => moment(cell).format('DD MMM, YYYY')
    curFormat = (cell, row) => amountformat.format(cell)

    amountFormat = (cell, row) => {
        let commission = this.state.commissions.filter(com => com.accountid == row.accountid && com.createdon == row.createdon)
        let comAmount = (commission[0] !== undefined) ? commission[0].amount : 0

        return amountformat.format(cell - comAmount)
    }

    transFormat = (cell, row) => `${naration[cell]} ${row.ticketSerial}`

    withinFormat = (cell, row) => 'Transfer of Credit from ' + row.ticketSerial + ' to ' + row.username

    descFormat = (cell, row) => {
        return cell + " by " + row.createdby
    }

    creditFormat = (cell, row) => {
        return (cell < 0) ? amountformat.format(row.credits) : amountformat.format(row.debits);
    }

    render() {
    
        return (
            <div className="blog-details-wrapper">
                <h3 className="post-title">My Units </h3>
                <hr />
                <div className="content">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="btn-group btn-group-sm pull-left">
                                <button className="btn btn-primary" data-toggle="modal" data-target="#purchaseUnitModal">Purchase Unit</button>
                                {this.state.roleid == 4 && 
                                <button className="btn btn-success" data-toggle="modal" data-target="#transferUnitModal">Transfer Unit</button>}
                                {this.state.roleid == 4 && 
                                <button className="btn btn-warning" data-toggle="modal" data-target="#changeUnitModal">Change Unit</button>}
                            </div>
                            <div className="btn-group btn-group-sm pull-right">
                                <span className="btn btn-default" >Unit Balance: GH&cent;<b>{amountformat.format(this.state.unit.closeBal)}</b></span>
                            </div>
                            <div className="clearfix" />
                            <hr />
                        </div>
                        <div className="col-lg-12">
                            <div className="faq-wrapper">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="purchased-tab" data-toggle="tab" href="#purchased" role="tab" aria-controls="purchased" aria-selected="false">Purchased</a>
                                    </li>
                                    {this.state.roleid == 4 && <li class="nav-item">
                                        <a class="nav-link" id="transferout-tab" data-toggle="tab" href="#transferout" role="tab" aria-controls="transferout" aria-selected="false">Transfer Out</a>
                                    </li>}
                                    {this.state.roleid == 4 && <li class="nav-item">
                                        <a class="nav-link" id="transferwithin-tab" data-toggle="tab" href="#transferwithin" role="tab" aria-controls="transferwithin" aria-selected="false">Transfer Within</a>
                                    </li>}
                                </ul>
                                <div className="tab-content" id="myTabContent" style={{padding:'0px'}}>
                                    <div className="tab-pane fade show active" id="purchased" role="tabpanel" aria-labelledby="purchased-tab">
                                        <BootstrapTable data={this.state.transactions} striped hover condensed search pagination>
                                            <TableHeaderColumn width="120px" dataField='createdon' isKey dataSort dataFormat={this.dateFormat}>Date</TableHeaderColumn>
                                            <TableHeaderColumn dataField='notes' dataSort dataFormat={this.descFormat}>Description</TableHeaderColumn>
                                            <TableHeaderColumn width="120px" dataField='amount' dataSort dataAlign="right" dataFormat={this.amountFormat}>Amount</TableHeaderColumn>
                                            <TableHeaderColumn width="120px" dataField='amount' dataSort dataAlign="right" dataFormat={this.commissionFormat}>C'ssion</TableHeaderColumn>
                                            <TableHeaderColumn width="120px" dataField='amount' dataSort dataAlign="right" dataFormat={this.curFormat}>Total</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                    {this.state.roleid == 4 && <div className="tab-pane fade" id="transferout" role="tabpanel" aria-labelledby="transferout-tab">
                                        <BootstrapTable data={this.state.transferdata.out} striped hover condensed search pagination>
                                            <TableHeaderColumn dataField='createdon' width="120px" isKey dataSort dataFormat={this.dateFormat}>Date</TableHeaderColumn>
                                            <TableHeaderColumn dataField='transSource' dataSort dataFormat={this.transFormat}>Description</TableHeaderColumn>
                                            <TableHeaderColumn dataField='transSource' width="130px" dataSort dataAlign="right" dataFormat={this.creditFormat}>Credit</TableHeaderColumn>
                                            <TableHeaderColumn dataField='closeBal' width="130px" dataSort dataAlign="right" dataFormat={this.curFormat}>Close Bal.</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>}
                                    {this.state.roleid == 4 && <div className="tab-pane fade" id="transferwithin" role="tabpanel" aria-labelledby="transferwithin-tab">
                                        <BootstrapTable data={this.state.transferdata.within} striped hover condensed search pagination>
                                            <TableHeaderColumn dataField='createdon' width="120px"  isKey dataSort dataFormat={this.dateFormat}>Date</TableHeaderColumn>
                                            <TableHeaderColumn dataField='transSource' dataSort dataFormat={this.withinFormat}>Description</TableHeaderColumn>
                                            <TableHeaderColumn dataField='transSource' width="150px"  dataSort dataAlign="right" dataFormat={this.creditFormat} >Amount (GH&cent;)</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PurchaseUnitForm getAgentTills={this.getAgentTills} getPurchased={this.getPurchased} />
                <TransferUnitForm writers={this.state.writers} getTransfers={this.getTransfers} />
                <ChangeUnitForm writers={this.state.writers} agentills={this.state.agentills} getTransfers={this.getTransfers} />
            </div>
        )
    }
}