import React, { Component } from "react"
import ReactDOM from 'react-dom'
import Auth from "../common/Auth"
import Config from "../Config"
import _ from 'lodash'
import axios from 'axios'
import Parse  from 'html-react-parser'
import { Link } from "react-router-dom"
import Preview from "./preview";

const cformat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })

/**
 * 
 * Cart which will display items in it
 * 
 */
export default class Cart extends Component {
  constructor(props) {
    super(props)

    this.auth = new Auth()
    this.config = new Config()
    this.$http = this.config.DConnect()

    this.state = {
      formItems: {isdn: 'MTN', phone: '', voucher:'', amount:0, cart:[]},
      cart: [], games: [], betting: [], totalAmount: 0
    }
  }

  componentDidMount() {
    this.getBetting()

    this.getGames()
  }

  getCart = () => {
    let cart = [], totalAmount = 0

    if(this.auth.getCart() !== null){
      cart = this.auth.getCart()
      totalAmount = _.reduce(cart, (total, itm) => {return total + (itm.noline * itm.amount)},0)
    }
     
    this.setState({ cart, totalAmount })
  }

  getGames = () => {
    this.$http.get('web/web/game')
      .then(res => {
        let games = this.auth.decryptData(res.data)
        this.getCart()
        this.setState({ games })
      })
  }

  getBetting = () => {
    this.$http.get('web/web/showonwebbetting')
      .then(res => {
        let betting = this.auth.decryptData(res.data)
        this.setState({ betting })
      })
  }

  getGamename = (code) => {
    var cod = parseInt(code)
    let result = this.state.games.filter(gt => gt.id == cod)

    if(result !=''){
      return result[0].gamename
    }
  }

  getBettingName = (code) => {
    var cod = parseInt(code)
    let mybet = this.state.betting.filter(bt => bt.id === cod)
    let result=(mybet !='')?mybet[0].betname:''
    return result
  }

  handleChange = (e) => {
    let formItems = this.state.formItems
    formItems[e.target.name] = e.target.value

    this.setState({ formItems })
  }

  formSubmit = (e) => {
    e.preventDefault()

    let formItems = this.state.formItems
    if(formItems.phone.trim() == ''){
      alert("Please enter a registered mobile money number...")
      return
    }else if(formItems.isdn == 'VDF' && formItems.voucher.trim() == ''){
      alert("Please generate a Vodafone voucher code and enter it...")
      return
    }

    formItems['cart'] = this.state.cart
    formItems['amount'] = this.state.totalAmount

    let formData =this.auth.encryptData(JSON.stringify(formItems))
    axios(this.config.PostOptions(formData, 'web/web/stakegame/momo'))
    .then((res) => {
      let resdata=this.auth.decryptText(res.data)
      alert(JSON.stringify(resdata))

      if(resdata == 'Complete the transaction on your phone...'){
        this.auth.unsetCart()
        window.location.reload(true)
        //this.componentDidMount()
      }
    })
  }
  
  deleteCart = (row) => {
    let cart = this.state.cart
    let index = cart.indexOf(row);
    if (index !== -1) cart.splice(index, 1);

    let totalAmount = _.reduce(cart, (total, itm) => {return total + (itm.noline * itm.amount)},0)

    this.setState({ cart, totalAmount })

    this.auth.setCart(JSON.stringify(cart))
  }

  render() {
    let sum = this.state.totalAmount
    let isdn = this.state.formItems.isdn
    let cart  = this.state.cart
    let stakeAgain = (cart[0] !== undefined) ? '/game/'+cart[0].game+'/'+this.getGamename(cart[0].game) : '#'

    return (
      <div>
        <section className="inner-page-banner has_bg_image" data-background="assets/images/inner-page-bg.jpg" style={{ backgroundImage: 'url(assets/images/inner-page-bg.jpg)' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-page-banner-area">
                  <h1 className="page-title">My Ticket</h1>
                  <nav aria-label="breadcrumb" className="page-header-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="">Home</a></li>
                      <li className="breadcrumb-item active">My Ticket</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cart-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="cart-area d-flex">
                  <div className="col-lg-9">
                    <div className="cart-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Game</th>
                            <th>Lines</th>
                            <th>Bet Type</th>
                            <th>Stake #</th>
                            <th>Amount</th>
                            <th>Total</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>

                          {this.state.cart.map(ct => (
                            <tr>
                              <td>{this.getGamename(ct.game)}</td>
                              <td>{ct.noline}</td>
                              <td>{this.getBettingName(ct.bettype)}</td>
                              <td>{Parse(ct.stakeNo.replace(' ~ ','<br><small><i>Against</i></small></br>'))}</td>
                              <td>{cformat.format(ct.amount)}</td>
                              <td>{cformat.format(ct.noline * ct.amount)}</td>
                              <td>
                                <button className="btn btn-sm btn-danger" onClick={() => this.deleteCart(ct)}><i className="fa fa-trash"></i> </button>
                              </td>
                            </tr>
                          ))}


                        </tbody>
                      </table>
                    </div>
                    <span className="pull-right mt-3">{this.state.cart.length} item</span>
                  </div>
                  <div className="col-lg-3">
                    
                    <div className="cart-summary-area">
                      <h3 className="summary-area-title">Ticket Summary</h3>
                      <form id="frmCart" method="POST" onSubmit={this.formSubmit}>
                        <div className="sub-total-amount">
                          <span className="title">Mobile Money Number: </span>
                          <span className="amount">
                            <input className="col-lg-1" type="radio" name="isdn" id="mtn" value="MTN" checked={isdn == 'MTN'} onChange={this.handleChange} />
                            <label className="col-lg-11" for="mtn">MTN</label>
                            <input className="col-lg-1" type="radio" name="isdn" id="atl" value="ATL" checked={isdn == 'ATL'} onChange={this.handleChange} />
                            <label className="col-lg-11" for="atl">AirtelTigo</label>
                            <input className="col-lg-1" type="radio" name="isdn" id="vdf" value="VDF" checked={isdn == 'VDF'} onChange={this.handleChange} />
                            <label className="col-lg-11" for="vdf">Vodafone</label>
                            <input type="text" name="phone" id="phone" value={this.state.formItems.phone} placeholder="e.g. 0553671948" onChange={this.handleChange} />
                            {isdn === 'VDF' && 
                            <input type="text" name="voucher" id="voucher" value={this.state.formItems.voucher} placeholder="Voucher code" onChange={this.handleChange} />}
                          </span>
                        </div>
                        <div className="total-amount">
                          <span className="title">total payment</span>
                          <span className="amount">Gh&cent;  {cformat.format(sum)}</span>
                          <button type="submit" className="payment-btn bg-warning text-danger"><b>Play now!</b></button>
                        </div>
                      </form>
                      <div className="card-area">
                        <div className="card-list">
                          <div className="btn-group">
                            <Link to={stakeAgain} className="btn btn-sm btn-info">Add extra bet...</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>

    )
  }
}