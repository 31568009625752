import Axios from 'axios';
import React, {useState, useEffect} from 'react';
import Auth from '../common/Auth';
import Config from '../Config';
import moment from 'moment';
import $ from 'jquery';

/**
 * 
 * Form to edit a user's profile details
 * 
 */
function ProfileForm(props){
    const [formItems, setFormItems] = useState(props.user);
    const [isDisabled, setIsDisabled] = useState(false);
    const config = new Config();
    const auth = new Auth();

    useEffect(() => {
        if(formItems !== props.user){
            setFormItems(props.user);
        }
    },[]);

    const handleChange = (e) => {
        e.preventDefault();

        let {name, value} = e.target;

        setFormItems(prevState => ({
            ...prevState, [name]:value
        }));
    }

    const updateProfile = (e) => {
        e.preventDefault();

        setIsDisabled(true);
        formItems['isNew'] = false;
        formItems['modifiedby'] = auth.getUser();
        formItems['modifiedon'] = moment();
        let formData = auth.encryptData(JSON.stringify(formItems))
        Axios(config.PostOptions(formData, 'web/web/register'))
        .then((res) => {
                alert(auth.decryptText(res.data))

                setIsDisabled(false)

                props.getUser(2);
                $('#btnMyProfile').click();
        }).catch((error)=>{alert(error);setIsDisabled(false);;});
    }

    return(
        <div class="modal fade registration-modal" id="profileModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div className="modal-header">
              <h3>Edit Profile Details</h3>
            </div>
            <div class="modal-body">
              <form class="registration-form cmn-frm">
              <div class="frm-group">
                  <label>First Name</label>
                  <input type="email" name="firstname" value={formItems.firstname} onChange={handleChange} required id="profile_email" placeholder="First Name" />
                  <i class="fa fa-user"></i>
                </div>
                <div class="frm-group">
                  <label>Last Name</label>
                  <input type="email" name="lastname" value={formItems.lastname} onChange={handleChange} required id="profile_email" placeholder="Last Name" />
                  <i class="fa fa-user"></i>
                </div>
                <div class="frm-group">
                  <label>Email Address</label>
                  <input type="email" name="email" value={formItems.email} onChange={handleChange} required id="profile_email" placeholder="Email Address" />
                  <i class="fa fa-user"></i>
                </div>
                <div class="frm-group">
                  <label>Mobile</label>
                  <input type="email" name="mobile" value={formItems.mobile} onChange={handleChange} required id="profile_mobile" placeholder="Mobile" />
                  <i class="fa fa-user"></i>
                </div>
                <div class="frm-group pull-right cmn-btn" >
                    <button id="btnMyProfile" type="button" style={{color:"white"}} className="btn btn-default" data-dismiss="modal">Cancel</button>
                    <button className="btn " style={{color:"white"}} type="submit" disabled={isDisabled} onClick={updateProfile} id="profile_submit">update</button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    )
}

export default ProfileForm;