import React, { Component } from "react"
import ReactDOM from 'react-dom'
import Auth from "../common/Auth"
import Config from "../Config"
import _ from 'lodash'
import moment from 'moment'

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

/**
 * 
 * Agent and writers dashboard
 * 
 */
export default class Dashboard extends Component{
    constructor(props) {
        super(props)
    
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            roleid: this.props.roleid,
            monthnum:moment().format('M'),
            unit:{id:0, openBal:0, closeBal:0, debits:0, credits:0},
            wallet:{id:0, openBal:0, closeBal:0, debits:0, credits:0},
            totalsales:0, commision:0, winnings:0, writers:0, totalStakes:0,
            todaySales:0, todayCommission:0, todayWinnings:0
        }
    }

    componentDidMount(){
        this.getUserTill()
    }

    componentDidUpdate(nextProps){
        if(nextProps.roleid !== this.state.roleid){
            let roleid = nextProps.roleid

            this.setState({roleid})
            this.getUserTill()
        }
    }

    getUserTill = () => {
        this.$http.get('web/web/' + this.auth.getUser() + '/usertill')
        .then(res => {
            let usertill = this.auth.decryptData(res.data)

            let unit = usertill.filter(rdata => rdata.tillType == '1' && rdata.status)
            let wallet = usertill.filter(rdata => rdata.tillType == '2' && rdata.status)

            //alert(JSON.stringify(usertill));
            this.$http.get('web/web/' + this.auth.getUser() + '/totalsales/' + this.state.monthnum)
            .then(res => {
                let totalsales = this.auth.decryptData(res.data)
                let rate = (this.state.roleid == 4) ? .04 : .3
                let commision = rate * totalsales

                this.$http.get('web/web/' + this.auth.getUser() + '/totalwriters')
                .then(res => {
                    let writers = this.auth.decryptData(res.data)
                
                    this.setState({ unit: unit[0], wallet: wallet[0], totalsales, writers, commision })

                    this.getTodaySales()
                })
            })
        })
    }

    getTodaySales = () => {
        this.$http.get('web/web/' + this.auth.getUser() + '/totalsales')
        .then(res => {
            let todaySales = this.auth.decryptData(res.data)
            let rate = (this.state.roleid == 4) ? .04 : .2
            let todayCommission = rate * todaySales

            this.$http.get('web/web/' + this.auth.getUser() + '/totalwins')
            .then(res => {
                let todayWinnings = this.auth.decryptData(res.data);

                //alert(todayWinnings)
                this.setState({ todaySales, todayCommission, todayWinnings });

                this.getWinings()
            }).catch((error)=>{alert(error )});
        }).catch((error)=>{alert(error )})
    }

    getWinings = () => {
        this.$http.get('web/web/' + this.auth.getUser() + '/totalwins/' + this.state.monthnum)
        .then(res => {
            let winnings = this.auth.decryptData(res.data)

            this.setState({ winnings })

            this.displayContent()
        }).catch((error)=>{alert(error )})
    }

    getStakes = () => {
        this.$http.get('web/web/' + this.auth.getUser() + '/totalstakes')
        .then(res => {
            let totalStakes = this.auth.decryptData(res.data)


            this.setState({ totalStakes })
        })
    }

    displayContent = () => {
        let roleid = this.state.roleid
        let monthname = moment().month(this.state.monthnum - 1).format("MMMM")
        let itm = <div className="row">
        <div className="col-lg-12">
            <div className="btn-group btn-group-sm pull-right" style={{marginBottom:'10px'}}>
                <select className="form-control form-control-sm btn" style={{width:'150px'}}>
                    <option>{monthname}</option>
                </select>
            </div>
        </div>
        {([4,5,6].includes(roleid)) &&
        <div className="col-xs-12 col-sm-6 col-md-4" >
            <div className=" bg-success text-center" style={{padding:'1px', marginBottom:'10px'}}>
                <div style={{padding:'5px'}} className="text-white">
                    <i className="fa fa-4x fa-money"></i><br />Wallet Balance
                </div>
                <div style={{padding:'5px'}} className="bg-white">GH&cent; {numformat.format(this.state.wallet.closeBal)}</div>
            </div>
        </div>}
        {([4,5].includes(roleid)) &&
        <div className="col-xs-12 col-sm-6 col-md-4">
            <div className=" bg-warning text-center" style={{padding:'1px', marginBottom:'10px'}}>
                <div style={{padding:'5px'}} className="text-white">
                    <i className="fa fa-4x fa-credit-card"></i><br />Unit Balance
                </div>
                <div style={{padding:'5px'}} className="bg-white">{numformat.format(this.state.unit.closeBal)}</div>
            </div>
        </div>}
        {([4].includes(roleid)) &&
        <div className="col-xs-12 col-sm-6 col-md-4">
            <div className=" bg-primary text-center" style={{padding:'1px', marginBottom:'10px'}}>
                <div style={{padding:'5px'}} className="text-white">
                    <i className="fa fa-4x fa-user-plus"></i><br />Writers
                </div>
                <div style={{padding:'5px'}} className="bg-white">{this.state.writers}</div>
            </div>
        </div>}
        {([4,5,6].includes(roleid)) &&
        <div className="col-xs-12 col-sm-6 col-md-4">
            <div className=" bg-info text-center" style={{padding:'1px', marginBottom:'10px'}}>
                <div style={{padding:'5px'}} className="text-white">
                    <i className="fa fa-4x fa-gift"></i><br />Today's Winnings
                </div>
                <div style={{padding:'5px'}} className="bg-white">GH&cent; {numformat.format(this.state.todayWinnings)}</div>
            </div>
        </div>}
        {([4,5].includes(roleid)) &&
        <div className="col-xs-12 col-sm-6 col-md-4">
            <div className=" bg-info text-center" style={{padding:'1px', marginBottom:'10px'}}>
                <div style={{padding:'5px'}} className="text-white">
                    <i className="fa fa-4x fa-calculator"></i><br />Today's Sales
                </div>
                <div style={{padding:'5px'}} className="bg-white">GH&cent; {numformat.format(this.state.todaySales)}</div>
            </div>
        </div>}
        {([4,5].includes(roleid)) && 
        <div className="col-xs-12 col-sm-6 col-md-4">
            <div className=" bg-info text-center" style={{padding:'1px', marginBottom:'10px'}}>
                <div style={{padding:'5px'}} className="text-white">
                    <i className="fa fa-4x fa-gift"></i><br />Today's Commission 
                </div>
                <div style={{padding:'5px'}} className="bg-white">GH&cent; {numformat.format(this.state.todayCommission)}</div>
            </div>
        </div>}
        {([4,5,6].includes(roleid)) &&
        <div className="col-xs-12 col-sm-6 col-md-4">
            <div className=" bg-danger text-center" style={{padding:'1px', marginBottom:'10px'}}>
                <div style={{padding:'5px'}} className="text-white">
                    <i className="fa fa-4x fa-gift"></i><br />{monthname} Winnings
                </div>
                <div style={{padding:'5px'}} className="bg-white">GH&cent; {numformat.format(this.state.winnings)}</div>
            </div>
        </div>}
        {([4,5].includes(roleid)) &&
        <div className="col-xs-12 col-sm-6 col-md-4">
            <div className=" bg-danger text-center" style={{padding:'1px', marginBottom:'10px'}}>
                <div style={{padding:'5px'}} className="text-white">
                    <i className="fa fa-4x fa-calculator"></i><br />{monthname} Sales
                </div>
                <div style={{padding:'5px'}} className="bg-white">GH&cent; {numformat.format(this.state.totalsales)}</div>
            </div>
        </div>}
        {([4,5].includes(roleid)) && 
        <div className="col-xs-12 col-sm-6 col-md-4">
            <div className=" bg-danger text-center" style={{padding:'1px', marginBottom:'10px'}}>
                <div style={{padding:'5px'}} className="text-white">
                    <i className="fa fa-4x fa-gift"></i><br />{monthname} Commission
                </div>
                <div style={{padding:'5px'}} className="bg-white">GH&cent; {numformat.format(this.state.commision)}</div>
            </div>
        </div>}
        {([6].includes(roleid)) &&
        <div className="col-xs-12 col-sm-6 col-md-4">
            <div className=" bg-info text-center" style={{padding:'1px', marginBottom:'10px'}}>
                <div style={{padding:'5px'}} className="text-white">
                    <i className="fa fa-4x fa-gamepad"></i><br />{monthname} Games Played
                </div>
                <div style={{padding:'5px'}} className="bg-white">{numformat.format(this.state.totalStakes)}</div>
            </div>
        </div>}
    </div>

    ReactDOM.render(itm, document.getElementById('prodashboardCon'))

    }

    render() {
    
        return (
            <div class="blog-details-wrapper">
                <h3 class="post-title">My Dashboard</h3>
                <hr />
                <div id="prodashboardCon" class="content">
                </div>
            </div>
        )
    }
}