import React, { Component } from "react"
import ReactDOM from 'react-dom'
import Auth from "../common/Auth"
import Config from "../Config"
import _ from 'lodash'

/**
 * 
 * Display a writers tickets staked
 * 
 */
export default class Games extends Component{
    constructor(props) {
        super(props)
    
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            
        }
    }

    render() {
    
        return (
            <div class="blog-details-wrapper">
                <div class="content">
                    <h3 class="post-title">My Tickets </h3>
                </div>
            </div>
        )
    }
}