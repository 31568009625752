import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery'
import Home from '../home/Home';
import About from '../about/About';
import Results from '../results/Results';
import Contact from '../contact/Contact';
import Promotion from '../home/Promotion';
import Games from '../games/Games';
import News from '../about/News';
import HowToPlay from '../about/HowToPlay';
import Disclaimer from '../about/Disclaimer';
import TermsConditions from '../about/TermsConditions';
import Faqs from '../about/Faqs';
import Profile from '../profile/Profile';


const cont = {
    '1': <Home />,
    '2':<Results />,

    '33':<Games gamecode='3' />,
    '34':<Games gamecode='4' />,
    '35':<Games gamecode='5' />,
    '37':<Games gamecode='7' />,
    '38':<Games gamecode='8' />,
    '39':<Games gamecode='9' />,
    '311':<Games gamecode='11' />,
    '312':<Games gamecode='12' />,
    '313':<Games gamecode='13' />,
    '314':<Games gamecode='14' />,
    '318':<Games gamecode='18' />,
    '319':<Games gamecode='19' />,
    '320':<Games gamecode='20' />,

    '41':<About />,
    '42':<News />,
    '43':<HowToPlay />,
    '44':<Faqs />,
    '45':<Disclaimer />,
    '44':<TermsConditions />,
    
    '5':<Contact />,
    '6':<Profile />
    
}

/**
 * 
 * A template to display all the pages
 * 
 */
export default class Content extends Component{
    constructor(props){
        super(props)

        this.state = {
            pagenum: this.props.pagenum
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.pagenum !== this.props.pagenum){
            let pagenum = nextProps.pagenum
            this.setState({ pagenum })

            //alert(pagenum)

            /*let lid = (pagenum==3) ? parseInt(pagenum/10): pagenum
            $('#left-panel nav ul li').removeClass('active')
            $('#left-panel nav ul li#' + lid).addClass('active')*/
        }
    }

    render(){
        return(
            <div>
                <div id="content">{cont[this.state.pagenum]}</div>
            </div>
        )
    }
}