import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config';
import moment from 'moment'
import axios from 'axios'

/**
 * 
 * Registers a new user onto the web app
 * 
 */
export default class Registration extends Component {
  constructor() {
    super()

    this.auth = new Auth();
    this.config = new Config()
    this.$http = this.config.DConnect()

    this.state = {
      companySetup: {
        companyName: '', address: '', phone: '', email: '', autologout: 5, logo: '', mobileApi: '',
        curuser: this.auth.getUser(), token: this.auth.getToken()
      },
      // registerForm: { email: "", mobile: "", password: "",firstname:"",lastname:"" }, 
      msg: "", checked: false,confirmpassword: "",
      registerForm: {
        username:'', firstname:'', lastname:'', middlename:'', filex:'',
        email:'', mobile:'', pin:'', auth:0, isNew:true, status:1,
        createdby:'', createdon: new Date(), password: '',
        modifiedby:null, modifiedon:null, token:0, 
        superior:'', curuser:'',isDisabled:false
    },
    }
  }

  componentDidMount() {
    this.getReportData()
  }

  getReportData = () => {
    this.$http.get('admin/web/setup')
      .then(res => {
        let companySetup = this.auth.decryptData(res.data)

        this.setState({ companySetup })
      }).catch(error => console.log(error.message));
  }

  handleChange = (e) => {
    let registerForm = this.state.registerForm
    registerForm[e.target.name] = e.target.value
    this.setState({ registerForm })
  }

  handleChanges = (e) => {
    let confirmpassword = this.state.confirmpassword
    confirmpassword = e.target.value
    let msg
    if (confirmpassword != this.state.registerForm.password) {
      msg = "Password not the same"
    }
    this.setState({ confirmpassword, msg })
  }

  aggreement = (e) => {
    this.setState({
      checked: !this.state.checked
    })
  }

  register = (e) => {
    e.preventDefault()

    if (!this.state.checked) {
      alert("You must accept the Terms and Conditions of Vision Lotto")
      return
    }

    this.setState({isDisabled:true})
    //alert(JSON.stringify(this.state.registerForm))
    let formData = this.auth.encryptData(JSON.stringify(this.state.registerForm))
    axios(this.config.PostOptions(formData, 'web/web/register'))
    .then((res) => {

            this.setState({isDisabled:false})

            window.location.reload(true)
    }).catch((error)=>{console.log(error);this.setState({isDisabled:false});})
  }
  render() {
    return (
      <div class="modal fade registration-modal" id="registrationModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div className="modal-header">
              <h3>New Registration</h3>
            </div>
            <div class="modal-body">

              <form class="registration-form cmn-frm">
              <div class="frm-group">
                  <label>First Name</label>
                  <input type="email" name="firstname" value={this.state.registerForm.firstname} onChange={this.handleChange} required id="registration_email" placeholder="First Name" />
                  <i class="fa fa-user"></i>
                </div>
                <div class="frm-group">
                  <label>Last Name</label>
                  <input type="email" name="lastname" value={this.state.registerForm.lastname} onChange={this.handleChange} required id="registration_email" placeholder="Last Name" />
                  <i class="fa fa-user"></i>
                </div>
                <div class="frm-group">
                  <label>Email Address</label>
                  <input type="email" name="email" value={this.state.registerForm.email} onChange={this.handleChange} required id="registration_email" placeholder="Email Address" />
                  <i class="fa fa-user"></i>
                </div>
                <div class="frm-group">
                  <label>Mobile</label>
                  <input type="email" name="mobile" value={this.state.registerForm.mobile} onChange={this.handleChange} required id="registration_mobile" placeholder="Mobile" />
                  <i class="fa fa-user"></i>
                </div>
                <div class="frm-group">
                  <label>Password</label>
                  <input type="password" name="password" value={this.state.registerForm.password} onChange={this.handleChange} required id="registration_pass" placeholder="Enter Password" />
                  <i class="fa fa-lock"></i>
                </div>
                <div class="frm-group">
                  <label>Confirm Password</label>
                  <input type="password" name="confirmpassword" value={this.state.confirmpassword} onChange={this.handleChanges} required id="registration_re_pass" placeholder="Enter Password" />
                  <i class="fa fa-lock"></i>
                  <span className="text-danger">{this.state.msg}</span>
                </div>
                <div class="frm-group pull-right cmn-btn" >
                  <button className="btn " style={{color:"white"}} type="submit" disabled={this.state.isDisabled} onClick={this.register} id="registration_submit">Register</button>
                </div>
                <br></br>
                <br></br>
                <div class="frm-group">
                  <div class="checkbox-area">
                    <input type="checkbox" name="agreement" value={this.state.checked} checked={this.state.checked} onChange={this.aggreement} id="registration_remember_pass" />
                    <label for="registration_remember_pass">I Agree with the Terms of Use</label>
                  </div>
                  <div class="forgot-pass-area">
                    <a href="home-one.html#0">Forgot Password?</a>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    )
  }

}