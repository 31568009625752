import React, { Component } from "react"
import ReactDOM from 'react-dom'
import Auth from "../common/Auth"
import Config from "../Config"
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CashoutForm from "./CashoutForm"
import LoadFundsForm from "./LoadFundsForm"

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

/**
 * 
 * Displays users wallet details
 * 
 */
export default class Wallet extends Component{
    constructor(props) {
        super(props)
    
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            startDate: moment(),
            endDate: moment(),
            formItems: {startDate: moment(),endDate: moment(), transource: 0},
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            unit:{id:0, openBal:0, closeBal:0, debits:0, credits:0},
            wallet:{id:0, openBal:0, closeBal:0, debits:0, credits:0},
            user:{},loader: false
        }
    }

    componentDidMount(){
        this.getUser();
    }

    handleEvent = (event, picker) => {
        let formItems = this.state.formItems

        formItems['startDate'] = picker.startDate
        formItems['endDate'] = picker.endDate
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            formItems
        });

        this.getReportData()
    }

    getUser = () => {
        this.setState({'loader':true});

        this.$http.get('web/web/' + this.auth.getUser() + '/usertill')
        .then(res => {
            let usertill = this.auth.decryptData(res.data)

            let unit = usertill.filter(rdata => rdata.tillType == '1' && rdata.status)
            let wallet = usertill.filter(rdata => rdata.tillType == '2' && rdata.status)

            this.$http.get('admin/web/user/' + this.auth.getUser())
            .then(res => {
                let user = this.auth.decryptData(res.data);

                //alert(JSON.stringify(wallet))
                this.setState({ unit: unit[0], wallet: wallet[0], user, 'loader':false });

                this.getReportData(1);
            })
        })
    }

    formatAmount = (cell, row) => numformat.format(cell);
    formatDate = (cell, row) => moment(cell).format('DD/MM/YYYY');

    getReportData = (transource) => {

        if(transource === undefined){
            return;
        }

        let formData = {...this.state.formItems};

        formData.transource = transource;

        this.setState({formItems: formData, loader: true});
        
        axios(this.config.PostOptions(formData, `web/web/walleTrans/${this.auth.getUser()}`))
        .then((res) => {
            
            let reportData = this.auth.decryptData(res.data);

            // alert(JSON.stringify(reportData))
            this.setState({'reportData':reportData, formItems: formData, 'loader':false});
        }).catch(error => alert(error.message));

        return;
    }

    loading = () => <div id="loader">Loading please wait...</div>;

    render() {

        if(this.state.loader){
            return this.loading();
        }

        let start = this.state.startDate.format('D MMM, YYYY'),
            end = this.state.endDate.format('D MMM, YYYY'),
            label = (start === end)? start : start + ' - ' + end;
    
        return (
            <div className="blog-details-wrapper">
                <h3 className="post-title">My Wallet </h3>
                <hr />
                <div className="content">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="btn-group btn-group-sm pull-left">
                                <button className="btn btn-primary" data-toggle="modal" data-target="#loadFundsModal">Load Funds</button>
                                <button className="btn btn-danger" data-toggle="modal" data-target="#cashoutModal">Cashout</button>
                            </div>
                            <div className="btn-group btn-group-sm pull-right">
                                <span className="btn btn-default">Wallet Balance: GH&cent;<b className="text-success">{numformat.format(this.state.wallet.closeBal)}</b></span>
                            </div>
                            <div className="clearfix" />
                            <hr />
                        </div>
                        <div className="col-lg-12">
                            <div className="faq-wrapper">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="loaded-tab" data-toggle="tab" href="#loaded" 
                                        role="tab" aria-controls="loaded" aria-selected="false" onClick={() => this.getReportData(1)}>Loaded</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="winnings-tab" data-toggle="tab" href="#winnings" 
                                        role="tab" aria-controls="winnings" aria-selected="false" onClick={() => this.getReportData(3)}>Winnings</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="cashouts-tab" data-toggle="tab" href="#cashouts" 
                                        role="tab" aria-controls="cashouts" aria-selected="false" onClick={() => this.getReportData(2)}>Cashouts</a>
                                    </li>
                                    {this.auth.getRole() == 'Agent' && 
                                    <li class="nav-item">
                                        <a class="nav-link" id="payments-tab" data-toggle="tab" href="#payments" 
                                        role="tab" aria-controls="payments" aria-selected="false" onClick={() => this.getReportData(4)}>Payments</a>
                                    </li>}
                                </ul>
                                <div className="tab-content" id="myTabContent" style={{padding:'0px'}}>
                                    <div className="tab-pane fade show active" id="loaded" role="tabpanel" aria-labelledby="loaded-tab">
                                        <div className="float-left">
                                            <DatetimeRangePicker startDate={this.state.startDate}
                                                endDate={this.state.endDate} ranges={this.state.ranges}
                                                onEvent={this.handleEvent} className="selected-date-range-btn btn btn-default">
                                                    <div className="pull-left"> <i className="fa fa-calendar" />&nbsp; &nbsp; <span>{label}</span> </div>
                                                    <div className="pull-right"> &nbsp; &nbsp;<i className="fa fa-angle-down" /> </div>
                                            </DatetimeRangePicker>
                                            <button onClick={() => this.getReportData(this.state.formItems.transource)} className="btn btn-info">Display</button>
                                        </div>
                                        <BootstrapTable data={_.orderBy(this.state.reportData, 'id', 'desc')} striped hover condensed search pagination>
                                            <TableHeaderColumn dataField='createdon' isKey dataSort dataFormat={this.formatDate} width="130px">Date</TableHeaderColumn>
                                            <TableHeaderColumn dataField='ticketSerial' dataSort >Description</TableHeaderColumn>
                                            <TableHeaderColumn dataField='debits' dataSort dataAlign="right"  width="140px" dataFormat={this.formatAmount}>Amount (GH&cent;)</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                    <div className="tab-pane fade" id="winnings" role="tabpanel" aria-labelledby="winnings-tab">
                                        <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                                            <TableHeaderColumn dataField='createdon' isKey dataSort dataFormat={this.formatDate} width="130px">Date</TableHeaderColumn>
                                            <TableHeaderColumn dataField='ticketSerial' dataSort >Description</TableHeaderColumn>
                                            <TableHeaderColumn dataField='debits' dataSort dataAlign="right"  width="140px" dataFormat={this.formatAmount}>Amount (GH&cent;)</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                    <div className="tab-pane fade" id="cashouts" role="tabpanel" aria-labelledby="cashouts-tab">
                                        <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                                            <TableHeaderColumn dataField='createdon' isKey dataSort dataFormat={this.formatDate} width="130px">Date</TableHeaderColumn>
                                            <TableHeaderColumn dataField='ticketSerial' dataSort >Description</TableHeaderColumn>
                                            <TableHeaderColumn dataField='debits' dataSort dataAlign="right" width="140px" dataFormat={this.formatAmount}>Amount (GH&cent;)</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                    <div className="tab-pane fade" id="payments" role="tabpanel" aria-labelledby="payments-tab">
                                        <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                                            <TableHeaderColumn dataField='createdon' isKey dataSort dataFormat={this.formatDate} width="130px">Date</TableHeaderColumn>
                                            <TableHeaderColumn dataField='ticketSerial' dataSort >Description</TableHeaderColumn>
                                            <TableHeaderColumn dataField='credits' dataSort dataAlign="right" width="140px" dataFormat={this.formatAmount}>Amount (GH&cent;)</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadFundsForm user={this.state.user} getUser={this.getUser} />
                <CashoutForm />
            </div>
        )
    }
}