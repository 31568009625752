import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config';
import moment from 'moment'
import axios from 'axios'
import $ from 'jquery'
import ReactDOM from 'react-dom'

/**
 * 
 * Forms used by Agent to register writers
 * 
 */
const ISDN = [{'id':'MTN', 'name':'MTN'}, {'id':'VDF', 'name':'Vodafone'}, {'id':'ATL', 'name':'Airtel'}, {'id':'TIGO', 'name':'Tigo'}]
export default class WriterForm extends Component {
    constructor(props) {
        super(props)

        this.auth = new Auth();
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems: {
                username: '', firstname: '', lastname: '', middlename: '', filex: '',
                email: '', mobile: '', pin: '', auth: 5, isNew: true, status: 1, isdn: '',
                createdby: this.auth.getUser(), createdon: new Date(), password: '',
                modifiedby: null, modifiedon: null, superior: this.auth.getUser(),
                curuser: this.auth.getUser()
            },
            contactItems: {
                id: 0, owner: 'profile', ownerId: '', phone: '', gps: '',
                country: 1, region: 1, area: '', location: '', address: '', gtgps: '',
                curuser: this.auth.getUser()
            },

            usertypeItems: {
                username: '', usertype: '3', superior: this.auth.getUser(), bankname: '', area: 'retailer',
                curuser: this.auth.getUser()
            },
            isUsertype: false,
            docItems: { picture_filename: null }
        }
    }

    componentDidMount = () => {

    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps !== this.props) {
            let curwriter = nextProps.curwriter
            let curcontact = nextProps.curcontact
            let formItems = {
                username: '', firstname: '', lastname: '', middlename: '', filex: '',
                email: '', mobile: '', pin: '', auth: 5, isNew: true, status: 1,
                createdby: this.auth.getUser(), createdon: new Date(), password: '',
                modifiedby: null, modifiedon: null, superior: this.auth.getUser(),
                curuser: this.auth.getUser()
            }

            let contactItems = {
                id: 0, owner: 'profile', ownerId: '', phone: '', gps: '',
                country: 1, region: 1, area: '', location: '', address: '', gtgps: '',
                curuser: this.auth.getUser()
            }

            let isUsertype = false

            if (curwriter.username !== undefined) {
                isUsertype = true
                formItems = curwriter
                contactItems = curcontact
            }

            this.setState({ formItems, contactItems, isUsertype })
        }
    }

    handleChange = (e) => {
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value

        this.setState({ formItems })
    }

    contactChange = (e) => {
        let contactItems = this.state.contactItems
        contactItems[e.target.name] = e.target.value

        this.setState({ contactItems })
    }

    imageClick = (id) => {
        document.getElementById(id).click()
    }

    imageChange = (e, picon) => {
        let img = e.target.files[0]
        let ext = img.name.split('.')
        ext = ext[ext.length - 1].toLowerCase()

        if (ext === 'jpg' || ext === 'jpeg' || ext === 'png') {
            let docItems = this.state.docItems
            let formItems = this.state.formItems
            docItems['picture_filename'] = img
            formItems['filex'] = ext

            this.setState({ docItems, formItems })

            let imgurl = URL.createObjectURL(img)
            ReactDOM.render(
                <img src={imgurl} style={{ width: '100px' }} />,
                document.getElementById(picon)
            )
        } else {
            alert('The supported files are: jpg and png...')
        }
    }

    goBack = () => {
        $('#btncancel').click()
        this.props.getWriters()
    }

    formSubmit = (e) => {
        e.preventDefault()

        let formItems = this.state.formItems
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        let contactItems = this.state.contactItems

        if (contactItems.location.trim() == '') {
            alert('Location Field is required...')
            return 0
        }
        contactItems['ownerId'] = formItems.username
        let contactData = this.auth.encryptData(JSON.stringify(contactItems))

        axios(this.config.PostOptions(formData, 'web/web/savewriter'))
            .then((res) => {
                this.saveImage(formItems.username, this.auth.decryptText(res.data))

                axios(this.config.PostOptions(contactData, 'web/web/contact'))
                    .then((ress) => {

                        if (!this.state.isUsertype) {
                            let usertypeItems = this.state.usertypeItems
                            usertypeItems['username'] = formItems.username
                            let utypeData = this.auth.encryptData(JSON.stringify(usertypeItems))
                            axios(this.config.PostOptions(utypeData, 'web/web/enablewriter'))
                                .then((res) => {


                                }).catch((error) => { alert(error + 'enablewriter') })
                        }
                        this.goBack()
                    }).catch((error) => { alert(error + 'contact') })

            }).catch((error) => { alert(error) })
    }

    saveImage = (username, msg) => {
        let docItems = this.state.docItems
        if (docItems.picture_filename !== null) {
            axios(this.config.PostMulti(docItems.picture_filename, 'web/web/writerfile/' + username + '.' + this.state.formItems.filex))
                .then((res) => {
                    alert(msg)
                    this.goBack()
                }).catch((error) => { alert(error) })
        } else {
            alert(msg)
            this.goBack()
        }
    }

    render() {
        return (
            <div className="modal fade login-modal" id="addwriterModal" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header"><h3>Add Writer</h3></div>
                        <div className="modal-body">
                            <form id="frmWriter" className="registration-form cmn-frm" onSubmit={this.formSubmit}>
                                <div className="row">
                                    <div className="col-lg-10">
                                        <div className="row">
                                            {!this.state.isUsertype &&
                                                <div className="col-xs-12  col-sm-6 frm-group">
                                                    <label>Username: <span className='text-danger'>*</span></label>
                                                    <input type="text" name="username" placeholder="Username" required
                                                        value={this.state.formItems.username} onChange={this.handleChange} />
                                                </div>}

                                            <div className="col-xs-12  col-sm-6 frm-group">
                                                <label>Firstname: <span className='text-danger'>*</span></label>
                                                <input type="text" name="firstname" placeholder="First Name" required
                                                    value={this.state.formItems.firstname} onChange={this.handleChange} />
                                            </div>

                                            <div className="col-xs-12  col-sm-6 frm-group">
                                                <label>Lastname: <span className='text-danger'>*</span></label>
                                                <input type="text" name="lastname" placeholder="Last Name" required
                                                    value={this.state.formItems.lastname} onChange={this.handleChange} />
                                            </div>

                                            <div className="col-xs-12  col-sm-6 frm-group">
                                                <label>Middlename: </label>
                                                <input type="text" name="middlename" placeholder="Middle Name"
                                                    value={this.state.formItems.middlename} onChange={this.handleChange} />
                                            </div>
                                            {!this.state.isUsertype &&
                                                <div className="col-xs-12  col-sm-6 frm-group">
                                                    <label>Email: <span className='text-danger'>*</span></label>
                                                    <input type="text" name="email" placeholder="Email Address"
                                                        value={this.state.formItems.email} onChange={this.handleChange} />
                                                </div>}

                                            <div className="col-xs-12  col-sm-6 frm-group">
                                                <label>Mobile: <span className='text-danger'>*</span></label>
                                                <input type="text" name="mobile" placeholder="Mobile Number" required
                                                    value={this.state.formItems.mobile} onChange={this.handleChange} />
                                            </div>
                                            <div className="col-xs-12  col-sm-6 frm-group">
                                                <label>Network: <span className='text-danger'>*</span></label>
                                                <select name="isdn" value={this.state.formItems.isdn} onChange={this.handleChange}>
                                                    {ISDN.map(isdn => <option value={isdn.id}>{isdn.name}</option>)}
                                                </select>
                                            </div>
                                            {!this.state.isUsertype &&
                                                <div className="col-xs-12  col-sm-6 frm-group">
                                                    <label >PIN: <span className='text-danger'>*</span></label>
                                                    <input type="text" name="pin" placeholder="PIN Code" minLength="4" maxLength="4" required
                                                        value={this.state.formItems.pin} onChange={this.handleChange} />
                                                </div>}
                                            {!this.state.isUsertype &&
                                                <div className="col-xs-12  col-sm-6 frm-group">
                                                    <label>Password: <span className='text-danger'>*</span></label>
                                                    <input type="password" name="password" placeholder="Password" minLength="8" required
                                                        value={this.state.formItems.password} onChange={this.handleChange} />
                                                </div>}

                                            <div className="col-xs-12  col-sm-6">
                                                <label>Phone: </label>
                                                <input type="text" name="phone" placeholder="phone"
                                                    value={this.state.contactItems.phone} onChange={this.contactChange} />
                                            </div>

                                            <div className="col-xs-12  col-sm-6">
                                                <label>Address: </label>
                                                <input type="text" name="address" placeholder="Address"
                                                    value={this.state.contactItems.address} onChange={this.contactChange} />
                                            </div>

                                            <div className="col-xs-12  col-sm-6">
                                                <label>Location: <span className='text-danger'>*</span></label>
                                                <input type="text" name="location" placeholder="Location"
                                                    value={this.state.contactItems.location} onChange={this.contactChange} />
                                            </div>

                                            {!this.state.isUsertype &&
                                                <div className="col-xs-12  col-sm-6">
                                                    <label>Gt GPS: </label>
                                                    <input type="text" name="gtgps" placeholder="Gt. GPS"
                                                        value={this.state.contactItems.gtgps} onChange={this.contactChange} />
                                                </div>}

                                            {!this.state.isUsertype &&
                                                <div className="col-xs-12  col-sm-6">
                                                    <label>Area: </label>
                                                    <input type="text" name="area" placeholder="Area"
                                                        value={this.state.contactItems.area} onChange={this.contactChange} />
                                                </div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div id="custImgCon" className="table-bordered imgCon text-center unselectable" onClick={() => this.imageClick('pfilename')}>
                                            <img src={this.config.ImagePath('custfiles') + '/' + this.state.formItems.username + '.' + this.state.formItems.filex} style={{ width: '100' }} alt="Photo" />
                                        </div>
                                        <input type="file" name="pictureFilename" style={{ display: 'none' }} id="pfilename" onChange={(e) => this.imageChange(e, 'custImgCon')} />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-group btn-group-sm pull-right clearfix">
                                <button id="btncancel" className="btn btn-warning" data-dismiss="modal">
                                    <i className="fa fa-lg fa-backward"></i> Cancel
                                </button>
                                <button type="submit" className="btn btn-success" form="frmWriter">
                                    <i className="glyphicon glyphicon-save"></i> Save Writer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}