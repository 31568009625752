import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config';
import moment from 'moment'

/**
 * 
 * Displays login page
 * 
 */
export default class Login extends Component{
    constructor(){
        super()

        this.auth = new Auth();
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            companySetup: {
                companyName:'', address:'', phone:'', email:'', autologout:5, logo:'', mobileApi:'',
                curuser:this.auth.getUser(), token:this.auth.getToken()
            },
            frmLogin:{email: "", pwd: "", role: 0}
        }
    }

    componentDidMount(){
        this.getReportData()
    }

    getReportData = () =>{
        this.$http.get('admin/web/setup')
        .then(res => {
            let companySetup = this.auth.decryptData(res.data)
            
            this.setState({ companySetup })
        }).catch((error)=>{console.log(error.message)})
    }

    handleChange = (e) => {
        let frmLogin = this.state.frmLogin
        frmLogin[e.target.name] = e.target.value
        this.setState({ frmLogin })
    }

    doLogin = (e) => {
        e.preventDefault()
        
        this.auth.login(this.state.frmLogin)
    }
    render(){
        return(
          <div className="modal fade login-modal" id="loginModal" tabindex="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header"><h3>User Login</h3></div>
                  <div className="modal-body">
                    <div className="login-registration-area">
                        <form className="login-form cmn-frm" onSubmit={this.doLogin}>
                          <div className="frm-group">
                            <label>Username or Email Address</label>
                            <input type="text" name="email" id="login_email" placeholder="Username or Email Address" 
                            value={this.state.frmLogin.email} onChange={this.handleChange} />
                            <i className="fa fa-user"></i>
                          </div>
                          <div className="frm-group">
                            <label>Password</label>
                            <input type="password" name="pwd" id="login_pass" placeholder="Enter Password" 
                            value={this.state.frmLogin.pwd} onChange={this.handleChange} />
                            <i className="fa fa-lock"></i>
                          </div>
                          <div className="frm-group">
                            <input type="submit" name="registration_submit2" id="registration_submit2" value="login" />
                          </div>
                          <div className="frm-group">
                            <div className="forgot-pass-area">
                              <a href="#">Forgot Password?</a>
                            </div>
                          </div>
                        </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )
    }

}